import { FC } from 'react'
import ExerciseRow from '../rows/ExerciseRow'
import TrainingRow from '../rows/TrainingRow '
import ActivityRow from '../rows/ActivityRow'

interface TableRowComponentProps {
	row: any
	selected?: any[]
	parentTable: 'exercises' | 'trainings' | 'activity' | 'assessments'
	id: string
	translate: (key: string) => string
}

const TableRowComponent: FC<TableRowComponentProps> = ({
	row,
	selected,
	translate,
	parentTable,
	id,
}) => {
	return (
		<>
			{parentTable === 'exercises' && (
				<ExerciseRow key={id} row={row} selected={selected} translate={translate} />
			)}
			{parentTable === 'trainings' && (
				<TrainingRow key={id} row={row} selected={selected} translate={translate} />
			)}
			{parentTable === 'activity' && (
				<ActivityRow key={id} row={row} translate={translate} />
			)}
		</>
	)
}

export default TableRowComponent
