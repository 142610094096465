import { useParams } from 'react-router-dom'
import {
	Box,
	Typography,
	LinearProgress,
	TableContainer,
	Grid,
	Paper,
	Table,
	TableBody,
	Button,
	Avatar,
} from '@mui/material'
import ludusTheme, {
	headerBoxStyles,
	headerTypographyStyles,
	tableContainerStyles,
} from '../../common/theme/ludusTheme.d'
import instructorIconGreen from '../images/Instructor_Icon_Green.png'
import { IoCalendar } from 'react-icons/io5'
import { MdAssignment } from 'react-icons/md'
import { useContext, useEffect, useState } from 'react'
import { GoChevronLeft, GoChevronRight } from 'react-icons/go'
import { LanguageContext } from '../../context/languageContext'
import SimulationPanel from '../components/common/SimulationPanel'
import BackButton from '../components/buttons/BackButton'
import { useSingleTraining } from '../hooks/useSinlgeTraining'
import Loader from '../components/common/Loader'
import { formatDateTraining } from '../utils/formatDate'
import PaginationControls from '../components/tables/common/PaginationControls'
import TableHeader from '../components/tables/common/TableHeader'
import TableRowComponent from '../components/tables/common/TableRow'
import { sortByStartTime } from '../utils/timeUtils'

const activitiesPerPage = 12
const attendeesPerPage = 6

const Training = () => {
	const { course } = useParams()
	const { loading, error, loadedTraining } = useSingleTraining(course)
	const [trainers, setTrainers] = useState<string[]>([])
	const [sortedActivities, setSortedActivities] = useState<any[]>([])
	const [paginatedAttendees, setPaginatedAttendees] = useState<string[]>([])
	const [totalAttendeesPages, setTotalAttendeesPages] = useState<number>(1)
	const {
		state: { language },
		dispatch: { translate },
	} = useContext(LanguageContext)

	useEffect(() => {
		if (!loading && !error && loadedTraining) {
			const trainingInstructors = loadedTraining.TrainingExercises?.flatMap(
				(exercise: { Session: { Trainer: { firstName: any; lastName: any }[] } }) =>
					exercise.Session.Trainer.map(
						(trainer: { firstName: any; lastName: any }) =>
							`${trainer.firstName} ${trainer.lastName}`
					)
			)

			setTrainers(trainingInstructors)

			const sorted = sortByStartTime(loadedTraining.TrainingExercises)
			setSortedActivities(sorted)

			const attendees = [...new Set(sorted.map((row) => row.UserEmail))]
			setPaginatedAttendees(attendees)
			setTotalAttendeesPages(Math.ceil(attendees.length / attendeesPerPage))
		}
	}, [loading, error, loadedTraining])

	//! TEMPORAL A LA ESPERA DE QUE PCVR METAN EN AZURE LA COLUMNA CONCLUSION
	const trainingCompletedExercises = 100

	const [currentActivityPage, setCurrentActivityPage] = useState<number>(1)
	const [currentAttendeePage, setCurrentAttendeePage] = useState<number>(1)

	const paginatedActivities = sortedActivities.slice(
		(currentActivityPage - 1) * activitiesPerPage,
		currentActivityPage * activitiesPerPage
	)

	const currentPageAttendees = paginatedAttendees.slice(
		(currentAttendeePage - 1) * attendeesPerPage,
		currentAttendeePage * attendeesPerPage
	)

	const assessmentsErrors = loadedTraining?.TrainingExercises.flatMap(
		(exercise) =>
			exercise.Assessments.map((assessment) => ({
				...assessment,
				Results: JSON.parse(assessment.Results),
			}))
	)

	const productsAssessments = loadedTraining?.TrainingExercises.map(
		(exercise) => {
			return {
				ProductId: exercise.Session.Product.ProductId,
				Name: exercise.Session.Product.Name,
				AssessmentErrorsDescriptions:
					exercise.Session.Product.AssessmentErrorsDescriptions,
			}
		}
	)
	const handlePrevActivityPage = () => {
		if (currentActivityPage > 1) {
			setCurrentActivityPage(currentActivityPage - 1)
		}
	}

	const handleNextActivityPage = () => {
		if (
			currentActivityPage <
			Math.ceil(loadedTraining?.TrainingExercises.length / activitiesPerPage)
		) {
			setCurrentActivityPage(currentActivityPage + 1)
		}
	}

	const handlePrevAttendeePage = () => {
		if (currentAttendeePage > 1) {
			setCurrentAttendeePage(currentAttendeePage - 1)
		}
	}

	const handleNextAttendeePage = () => {
		if (currentAttendeePage < totalAttendeesPages) {
			setCurrentAttendeePage(currentAttendeePage + 1)
		}
	}

	const getInitials = (name: string) => {
		const names = name.split(' ')
		const initials = names.map((n) => n[0]).join('')
		return initials
	}

	if (loading) return <Loader />
	if (error) return <p>Error: {error.message}</p>
	if (!loading && !error && loadedTraining)
		return (
			<Box
				sx={{
					backgroundColor: ludusTheme.palette.secondary.main,
					marginTop: 3,
					padding: { xs: 3, lg: 2, xl: 3 },
					borderRadius: '20px',
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Box sx={{ flex: 1 }}>
						<BackButton />
					</Box>
					<Box sx={{ flex: 8, textAlign: 'center' }}>
						<Typography variant="h4" sx={headerTypographyStyles}>
							{loadedTraining.Name}
						</Typography>
					</Box>
					<Box sx={{ flex: 1 }}></Box>
				</Box>
				<Box sx={{ position: 'relative', marginTop: 4 }}>
					{/* COMPONENTE DATOS GENERALES */}
					<TableContainer
						sx={{
							...tableContainerStyles,
							minHeight: '200px',
							overflowX: 'auto',
						}}
					>
						<Box sx={{ ...headerBoxStyles, padding: { xxs: 2, xs: 2, md: 3, lg: '10px' } }}>
							<Typography
								variant="h5"
								sx={{ ...headerTypographyStyles, textAlign: 'center' }}
							>
								{translate('title_table_general_data')}
							</Typography>
						</Box>
						<Grid container spacing={3} sx={{ padding: { xxs: 2, xs: 2, md: 3, lg: 4 } }}>
							<Grid item xs={12} md={4}>
								<Paper
									sx={{
										marginInline: 'auto',
										padding: { xxs: 2, xs: 2, md: 3, lg: 5 },
										display: 'flex',
										alignItems: 'center',
										backgroundColor: ludusTheme.palette.secondary.main,
										borderRadius: '10px',
										minWidth: { xxs: '300px', xs: '90%', md: '100%' },
									}}
								>
									<Box
										component="img"
										src={instructorIconGreen}
										alt="Logo"
										sx={{
											width: { xxs: 30, xs: 30, md: 40, xl: 50 },
											height: { xxs: 30, xs: 30, md: 40, xl: 50 },
											marginRight: '20px',
										}}
									/>
									<Box sx={{ color: 'white', display: 'flex', flexDirection: 'column' }}>
										<Typography
											variant="body2"
											sx={{ fontSize: { xxs: '10px', xs: '10px', md: '10px', lg: '12px', xl: '14px' } }}
										>
											{translate('title_register_training_by').toUpperCase()}
										</Typography>
										<Typography
											variant="h5"
											sx={{ fontSize: { xxs: '10px', xs: '10px', md: '14px', lg: '18px', xl: '20px' } }}
										>
											{Array.from(new Set(trainers)).join(', ')}
										</Typography>
									</Box>
								</Paper>
							</Grid>
							<Grid item xs={12} md={4}>
								<Paper
									sx={{
										marginInline: 'auto',
										padding: { xxs: 2, xs: 2, md: 3, lg: 5 },
										display: 'flex',
										alignItems: 'center',
										backgroundColor: ludusTheme.palette.secondary.main,
										borderRadius: '10px',
										minWidth: { xxs: '300px', xs: '90%', md: '100%' },
									}}
								>
									<IoCalendar size={50} color="#5D8A8E" />
									<Box
										sx={{
											color: 'white',
											marginLeft: { xxs: '10px', xs: '10px', md: '10px', lg: '5%', xl: '5%' },
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<Typography
											variant="body2"
											sx={{
												fontSize: { xxs: '10px', xs: '10px', md: '10px', lg: '12px', xl: '14px' },
												marginTop: 0,
											}}
										>
											{translate('column_table_start_date')}
										</Typography>
										<Typography
											variant="h5"
											sx={{ fontSize: { xxs: '10px', xs: '10px', md: '14px', lg: '16px', xl: '20px' } }}
										>
											{formatDateTraining(loadedTraining.StartTime)}
										</Typography>
									</Box>
									<Box
										sx={{
											color: 'white',
											marginLeft: { xxs: '10px', xs: '10px', md: '10px', lg: '10%', xl: '15%' },
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<Typography
											variant="body2"
											sx={{
												fontSize: { xxs: '10px', xs: '10px', md: '10px', lg: '12px', xl: '14px' },
												marginTop: 0,
											}}
										>
											{translate('column_table_end_date')}
										</Typography>
										<Typography
											variant="h5"
											sx={{ fontSize: { xxs: '10px', xs: '10px', md: '14px', lg: '16px', xl: '20px' } }}
										>
											{formatDateTraining(loadedTraining.EndTime)}
										</Typography>
									</Box>
								</Paper>
							</Grid>
							<Grid item xs={12} md={4}>
								<Paper
									sx={{
										marginInline: 'auto',
										padding: { xxs: 2, xs: 2, md: 3, lg: 5 },
										display: 'flex',
										alignItems: 'center',
										backgroundColor: ludusTheme.palette.secondary.main,
										borderRadius: '10px',
										minWidth: { xxs: '300px', xs: '90%', md: '100%' },
									}}
								>
									<MdAssignment size={50} color="#5D8A8E" />
									<Box
										sx={{
											width: '100%',
											color: 'white',
											marginLeft: '20px',
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<Typography
											variant="body2"
											sx={{
												fontSize: { xxs: '10px', xs: '10px', md: '12px', xl: '14px' },
												marginBottom: 0,
											}}
										>
											{translate('column_table_completed_exercises')}
										</Typography>
										<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
											<LinearProgress
												variant="determinate"
												value={loadedTraining.completed}
												sx={{
													flexGrow: 1,
													height: 20,
													borderRadius: '20px',
													backgroundColor: ludusTheme.palette.primary.dark,
													'& .MuiLinearProgress-bar': {
														backgroundColor: ludusTheme.palette.primary.main,
														borderRadius: '20px',
													},
												}}
											/>
											<Typography
												variant="h5"
												sx={{ fontSize: { xs: '14px', md: '18px', xl: '20px' } }}
											>
												{trainingCompletedExercises}%
											</Typography>
										</Box>
									</Box>
								</Paper>
							</Grid>
						</Grid>
					</TableContainer>

					{/* COMPONENTE ACTIVIDAD */}
					<TableContainer
						sx={{ ...tableContainerStyles, minHeight: '100%', overflowX: 'auto' }}
					>
						<Box sx={headerBoxStyles}>
							<Typography variant="h5" sx={headerTypographyStyles}>
								{translate('title_table_activity')}
							</Typography>
						</Box>
						<Grid
							item
							xs={12}
							md={4}
							sx={{
								paddingInline: { xxs: 2, xs: 2, md: 3, lg: 3 },
								paddingTop: { xxs: 2, xs: 2, md: 3, lg: 3 },
								paddingBottom: 0,
								borderRadius: '10px',
								minWidth: { xxs: '90%', md: '100%' },
							}}
						>
							<TableContainer
								component={Paper}
								sx={{
									backgroundColor: ludusTheme.palette.info.dark,
									boxShadow: 'none',
									border: '2px solid white',
									textAlign: 'center',
									borderRadius: '20px',
									marginBlock: '10px',
								}}
							>
								<Table
									sx={{
										minWidth: 650,
										'& .MuiTableCell-root': {
											color: 'white',
											padding: '10px 0px',
											borderRight: '2px solid white',
											whiteSpace: 'nowrap',
										},
										'& .MuiTableCell-head': {
											fontWeight: 'bold',
										},
									}}
								>
									<TableHeader
										translate={translate}
										headerKeyIds={[
											'column_table_time',
											'column_table_employee_activity',
											'column_table_simulation_activity',
											'column_table_result_activity',
										]}
									/>
									<TableBody>
										{paginatedActivities.map((row: any) => {
											return (
												<TableRowComponent
													key={row.ExerciseId}
													row={row}
													selected={[]}
													parentTable={'activity'}
													id={row.ExerciseId}
													translate={translate}
												/>
											)
										})}
									</TableBody>
								</Table>
							</TableContainer>

							<PaginationControls
								currentPage={currentActivityPage}
								totalPages={Math.ceil(
									loadedTraining.TrainingExercises?.length / activitiesPerPage
								)}
								onPrevPage={handlePrevActivityPage}
								onNextPage={handleNextActivityPage}
							/>
						</Grid>
					</TableContainer>

					{/* COMPONENTE ASISTENCIA */}
					<TableContainer
						sx={{ ...tableContainerStyles, minHeight: '100%', overflowX: 'auto' }}
					>
						<Box sx={headerBoxStyles}>
							<Typography variant="h5" sx={headerTypographyStyles}>
								{translate('title_table_attendance')}
							</Typography>
						</Box>
						<Grid container>
							{currentPageAttendees.map((name: string) => (
								<Grid item xs={12} sm={6} md={4} key={name}>
									<Paper
										elevation={0}
										variant="outlined"
										sx={{
											display: 'flex',
											alignItems: 'center',
											padding: 2,
											backgroundColor: 'transparent',
											border: '0',
											color: 'white',
										}}
									>
										<Avatar
											sx={{
												marginInline: 2,
												width: {
													xs: '20px',
													sm: '30px',
													md: '40px',
													lg: '50px',
													xl: '60px',
												},
												height: {
													xs: '20px',
													sm: '30px',
													md: '40px',
													lg: '50px',
													xl: '60px',
												},
												backgroundColor: '#688E92',
												color: ludusTheme.palette.primary.dark,
												fontFamily: 'Barlow Condensed',
												fontWeight: 'bold',
												fontSize: {
													xs: '14px',
													sm: '16px',
													md: '18px',
													lg: '20px',
													xl: '24px',
												},
											}}
										>
											{getInitials(name)}
										</Avatar>
										<Typography
											sx={{
												fontSize: {
													xs: '14px',
													sm: '16px',
													md: '18px',
													lg: '20px',
													xl: '24px',
												},
											}}
										>
											{name}
										</Typography>
									</Paper>
								</Grid>
							))}
						</Grid>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginBottom: '15px',
							}}
						>
							<Button
								onClick={handlePrevAttendeePage}
								disabled={currentAttendeePage === 1}
								sx={{
									minWidth: '24px',
									minHeight: '24px',
									color: ludusTheme.palette.info.main,
									padding: 0,
									backgroundColor: 'transparent',
									borderRadius: '20px',
									'&:hover': {
										backgroundColor: 'rgb(53, 93, 98, 0.3)',
										borderRadius: '20px',
									},
									'&.Mui-disabled': {
										color: ludusTheme.palette.info.main,
									},
								}}
							>
								<GoChevronLeft size={25} />
							</Button>
							<Typography
								variant="body1"
								sx={{ color: ludusTheme.palette.info.main, margin: 1 }}
							>{`${currentAttendeePage}/${totalAttendeesPages}`}</Typography>
							<Button
								onClick={handleNextAttendeePage}
								disabled={currentAttendeePage === totalAttendeesPages}
								sx={{
									minWidth: '24px',
									minHeight: '24px',
									color: ludusTheme.palette.info.main,
									padding: 0,
									backgroundColor: 'transparent',
									borderRadius: '20px',
									'&:hover': {
										backgroundColor: 'rgb(53, 93, 98, 0.3)',
										borderRadius: '20px',
									},
									'&.Mui-disabled': {
										color: ludusTheme.palette.info.main,
									},
								}}
							>
								<GoChevronRight size={25} />
							</Button>
						</Box>
					</TableContainer>
				</Box>

				<SimulationPanel
					language={language}
					assessmentsErrors={assessmentsErrors}
					productsAssessments={productsAssessments}
					translate={translate}
				/>
			</Box >
		)
}

export default Training
