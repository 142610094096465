import { FC } from 'react'
import { TableRow, TableCell, Button } from '@mui/material'
import { formatDate, formatDuration } from '../../../utils/formatDate'
import {
	buttonInstructorStyles,
	tdTableStyles,
} from '../../../../common/theme/ludusTheme.d'
import { shortenName } from '../../../utils/shortenName'

type ExerciseRowProps = {
	row: any
	selected: any[]
	translate: (key: string) => string
}

const ExerciseRow: FC<ExerciseRowProps> = ({ row, selected, translate }) => {

	const shortenedProductName = translate(`label_product_${row.Session.Product.Name}`)

	return (
		<TableRow
			key={row.ExerciseId}
			sx={{
				backgroundColor: selected.find(
					(selectedExercise) => selectedExercise.ExerciseId === row.ExerciseId
				)
					? '#006C52'
					: 'inherit',
				'&:hover': {
					backgroundColor: selected.find(
						(selectedExercise) => selectedExercise.ExerciseId === row.ExerciseId
					)
						? '#006C52'
						: '#555',
				},
				...tdTableStyles,
			}}
		>
			<TableCell>{formatDate(row.StartTime)}</TableCell>
			<TableCell>{formatDuration(row.EndTime - row.StartTime)}</TableCell>
			<TableCell>
				<Button sx={buttonInstructorStyles}>
					{row.Session.Trainer[0].firstName} {row.Session.Trainer[0].lastName}
				</Button>
			</TableCell>
			<TableCell>{row.UserEmail}</TableCell>
			<TableCell>
				{row.Employee?.Client?.Name
					? shortenName(row.Employee.Client.Name)
					: shortenName(row.Employee?.Client?.ComercialName || '-')}
			</TableCell>
			<TableCell>{shortenName(shortenedProductName)}</TableCell>
			<TableCell>
				{/* {translate('column_table_completed')} */}
				{row.Passed
					? translate('column_table_completed')
					: translate('column_table_not_completed')}
			</TableCell>
		</TableRow>
	)
}

export default ExerciseRow
