import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import AppV1 from './v1/App'
import Login from './v1/layouts/login'
import { useContext, useEffect } from 'react'
import { LanguageContext } from './context/languageContext'

const AppRouter = () => {
	const {
		dispatch: { setLanguage },
	} = useContext(LanguageContext)
	const location = useLocation()
	useEffect(() => {
		const searchString = location.search.toLowerCase()
		if (searchString.includes('lang')) {
			const urlLanguage = searchString.toLowerCase().split(':')[1]
			window.localStorage.setItem('language', urlLanguage)
			setLanguage(urlLanguage)
		}
	}, [location])

	return (
		<Routes>
			<Route path="/" element={<Navigate to="/v1/statistics" />} />
			<Route path="/login/:jwe" element={<Login />} />
			<Route path="/v1" element={<Navigate to="/v1/statistics" />} />
			<Route path="/v1/*" element={<AppV1 />} />
		</Routes>
	)
}

export default AppRouter
