import axios from 'axios'
import { LoginUserResponse } from '../types/types.d'

export const loginUserOnline = async (accessToken) => {
	return await axios
		.post<LoginUserResponse>(
			`${process.env.REACT_APP_AUTH_API_URL}/api/users/`,
			{ accessToken },
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
					Accept: 'application/json',
				},
			}
		)
		.then((response) => {
			return response.data
		})
		.catch((error) => {
			return error.response
		})
}
