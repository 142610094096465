import { FC, useContext, useEffect, useState } from 'react';
import { Box, Slider, Typography } from '@mui/material';
import { LanguageContext } from '../../../context/languageContext';
import ludusTheme from '../../../common/theme/ludusTheme.d';

type MonthSliderProps = {
    onDatesChange: (dates: [Date, Date]) => void;
};

const MonthSlider: FC<MonthSliderProps> = ({ onDatesChange }) => {
    const [value, setValue] = useState<number>(6);
    const {
        dispatch: { translate },
    } = useContext(LanguageContext);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };

    useEffect(() => {
        const startDate = new Date();
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() - value);
        onDatesChange([endDate, startDate]);
    }, [value]);

    return (
        <Box sx={{ textAlign: 'center', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ marginRight: '10px', color: 'black', fontWeight: 'bold' }}>0</Typography>
                <Slider
                    value={value}
                    min={0}
                    max={12}
                    onChange={handleSliderChange}
                    aria-labelledby="month-slider"
                    sx={{
                        color: '#BEBEBE',
                        flexGrow: 1,
                        '& .MuiSlider-thumb:hover': {
                            border: '2px solid',
                            borderColor: ludusTheme.palette.primary.main,
                            boxShadow: 'none'
                        },
                        '& .MuiSlider-track': {
                            border: 'none',
                        },
                        '& .MuiSlider-rail': {
                            border: 'none',
                        },
                    }}
                />
                <Typography sx={{ marginLeft: '10px', color: 'black', fontWeight: 'bold' }}>12</Typography>
            </Box>
            <Typography sx={{ color: 'black', fontWeight: '500' }}>
                {`${value} ${translate('button_months').toLowerCase()}`}
            </Typography>
            <Typography sx={{ color: 'black', fontWeight: '500' }}>
                {`${new Date(new Date().setMonth(new Date().getMonth() - value)).toLocaleDateString()} - ${new Date().toLocaleDateString()}`}
            </Typography>
        </Box>
    );
};

export default MonthSlider;
