import { FC } from 'react'
import { TableHead, TableRow, TableCell } from '@mui/material'
import { tableHeadStyles } from '../../../../common/theme/ludusTheme.d'

interface TableHeaderProps {
	translate: (key: string) => string
	headerKeyIds: string[]
}

const TableHeader: FC<TableHeaderProps> = ({ translate, headerKeyIds }) => {
	return (
		<TableHead sx={tableHeadStyles}>
			<TableRow>
				{headerKeyIds.map((headerKeyId) => (
					<TableCell>{translate(headerKeyId)}</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

export default TableHeader
