import React, { createContext, useContext, useState } from 'react'

interface FilterContextProps {
	exercisesFilters: {
		trainer: string[]
		company: string[]
		product: string[]
		date: any
	}
	setExercisesFilters: React.Dispatch<
		React.SetStateAction<{
			trainer: string[]
			company: string[]
			product: string[]
			date: any
		}>
	>
	trainingsFilters: {
		trainer: string[]
		company: string[]
		product: string[]
		date: any
	}
	setTrainingsFilters: React.Dispatch<
		React.SetStateAction<{
			trainer: string[]
			company: string[]
			product: string[]
			date: any
		}>
	>
	assessmentsFilters: {
		trainer: string[]
		company: string[]
		product: string[]
		date: any
	}
	setAssessmentsFilters: React.Dispatch<
		React.SetStateAction<{
			trainer: string[]
			company: string[]
			product: string[]
			date: any
		}>
	>
}

const FilterContext = createContext<FilterContextProps | undefined>(undefined)

export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [exercisesFilters, setExercisesFilters] = useState({
		trainer: [],
		company: [],
		product: [],
		date: [],
	})

	const [trainingsFilters, setTrainingsFilters] = useState({
		trainer: [],
		company: [],
		product: [],
		date: [],
	})

	const [assessmentsFilters, setAssessmentsFilters] = useState({
		trainer: [],
		company: [],
		product: [],
		date: [],
	})

	return (
		<FilterContext.Provider
			value={{
				exercisesFilters,
				setExercisesFilters,
				trainingsFilters,
				setTrainingsFilters,
				assessmentsFilters,
				setAssessmentsFilters,
			}}
		>
			{children}
		</FilterContext.Provider>
	)
}

export const useFilterContext = () => {
	const context = useContext(FilterContext)
	if (!context) {
		throw new Error('useFilterContext must be used within a FilterProvider')
	}
	return context
}
