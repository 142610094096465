import { Box, CircularProgress, CircularProgressProps, Typography } from "@mui/material";
import ludusTheme, { circularProgressStyles } from "../../../common/theme/ludusTheme.d";

const CircularProgressLabel = (props: CircularProgressProps & { value: number }) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                value={100}
                sx={{
                    ...circularProgressStyles,
                    color: '#003139',
                    position: 'absolute',
                }}
            />
            <CircularProgress
                variant="determinate"
                {...props}
                sx={{
                    ...circularProgressStyles,
                    color: ludusTheme.palette.warning.main,
                }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xxs: '12px', xs: '12px', sm: '12px', md: '14px', lg: "18px", xl: "20px" },
                        color: 'white'
                    }}>
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressLabel