import { FC, useContext } from 'react'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Button,
	IconButton,
} from '@mui/material'
import { FaRegTrashCan } from 'react-icons/fa6'
import { IoMdClose } from 'react-icons/io'
import { LanguageContext } from '../../../context/languageContext'

type EliminationModalProps = {
	open: boolean
	onClose: () => void
	onConfirm: () => void
	selectedRows: any
}

const EliminationModal: FC<EliminationModalProps> = ({
	open,
	onClose,
	onConfirm,
	selectedRows,
}) => {
	const {
		dispatch: { translate },
	} = useContext(LanguageContext)

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			PaperProps={{
				sx: {
					borderRadius: '20px',
					maxWidth: '800px',
					height: '400px',
					padding: '50px',
					backgroundColor: '#002126',
					textAlign: 'center',
				},
			}}
		>
			<IconButton
				onClick={onClose}
				sx={{
					position: 'absolute',
					top: 8,
					right: 8,
					color: 'white',
					'&:hover': { backgroundColor: '#003e47' },
				}}
			>
				<IoMdClose />
			</IconButton>
			<DialogContent sx={{ backgroundColor: '#002126' }}>
				<DialogContentText
					id="alert-dialog-description"
					sx={{
						color: 'white',
						fontSize: { xxs: 10, xs: 12, sm: 14, md: 18, lg: 20, xl: 20 },
					}}
				>
					{translate('alert_delete_trainings_pt1')} {selectedRows.length}{' '}
					{translate('alert_delete_trainings_pt2')} <br />
					{translate('alert_delete_trainings_pt3')} <br />
					{translate('alert_delete_trainings_pt4')} <br />
					{translate('alert_delete_trainings_pt5')}
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', backgroundColor: '#002126' }}>
				<Button
					variant="contained"
					onClick={onConfirm}
					startIcon={<FaRegTrashCan />}
					sx={{
						backgroundColor: '#002B31',
						color: 'white',
						fontFamily: 'Barlow Condensed !important',
						fontWeight: '500',
						marginRight: '20px',
						borderRadius: '25px',
						fontSize: { xxs: 10, xs: 10, sm: 12, md: 12, lg: 16 },
						'&:hover': { backgroundColor: '#893b3b' },
					}}
				>
					{translate('button_alert_delete_trainings_yes')}
				</Button>
				<Button
					variant="contained"
					startIcon={<IoMdClose />}
					onClick={onClose}
					sx={{
						backgroundColor: '#002B31',
						color: 'white',
						fontFamily: 'Barlow Condensed !important',
						fontSize: { xxs: 10, xs: 10, sm: 12, md: 12, lg: 16 },
						fontWeight: '500',
						marginRight: '20px',
						borderRadius: '25px',
						'&:hover': { backgroundColor: '#003e47' },
					}}
				>
					{translate('button_alert_delete_trainings_no')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default EliminationModal
