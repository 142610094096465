import { FC, useContext, useEffect, useState } from 'react'
import { Box, Typography, Dialog } from '@mui/material'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import TimeModal from './TimeModal'
import { dateTimePaperPropsStyles } from '../../../common/theme/ludusTheme.d'
import { LanguageContext } from '../../../context/languageContext'
import '../../../common/daysLocals'
import CloseModalButton from '../buttons/CloseModalButton'
import SimpleButton from '../buttons/SimpleButton'
type DateTimeNewFormationModalProps = {
	onClose: () => void
	onFilterApply: (selectedDate: Date, time: string) => void
	open: boolean
	startDate?: Date | null
	endDate?: Date | null
	initialTime?: string
}

const DateTimeNewFormationModal: FC<DateTimeNewFormationModalProps> = ({
	onClose,
	onFilterApply,
	open,
	startDate,
	endDate,
	initialTime = '00:00',
}) => {
	const [selectedDate, setSelectedDates] = useState<Date>()
	const [currentDate, setCurrentDate] = useState<Dayjs | null>(null)
	const [time, setTime] = useState(initialTime)
	const {
		state: { language },
		dispatch: { translate },
	} = useContext(LanguageContext)

	useEffect(() => {
		if (startDate) {
			const dayjsStartDate = dayjs(startDate)
			setSelectedDates(startDate)
			setCurrentDate(dayjsStartDate)
		}
		if (endDate) {
			const dayjsEndDate = dayjs(endDate)
			setSelectedDates(endDate)
			setCurrentDate(dayjsEndDate)
		}
	}, [startDate, endDate])

	const handleDateChange = (date: Dayjs | null) => {
		if (date) {
			setSelectedDates(date.toDate())
		}
		setCurrentDate(date)
	}

	const handleTimeFilterApply = (inputTime: string) => {
		setTime(inputTime)
	}

	const handleFilterApply = () => {
		onFilterApply(selectedDate, time)
		onClose()
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {
					...dateTimePaperPropsStyles.sx,
					borderRadius: '20px',
					minWidth: '300px',
					padding: '20px',
					backgroundColor: '#F0F0F0',
					color: 'white',
					zIndex: 3,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					margin: '0',
				},
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					mb: 2,
				}}
			>
				<Typography variant="h6" sx={{ color: '#707070', marginBottom: '-18px' }}>
					{translate('text_dropdown_select_date')}
				</Typography>
			</Box>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
				<StaticDatePicker
					showDaysOutsideCurrentMonth
					fixedWeekNumber={6}
					displayStaticWrapperAs="desktop"
					value={currentDate}
					onChange={handleDateChange}
					sx={{ color: 'black', backgroundColor: '#F0F0F0' }}
				/>
			</LocalizationProvider>
			<TimeModal onFilterApply={handleTimeFilterApply} initialTime={initialTime} />
			<Box sx={{ display: 'flex', gap: 6 }}>
				<SimpleButton
					onClick={handleFilterApply}
					label={translate('button_dropdown_accept')}
				/>
				<CloseModalButton onClick={onClose} />
			</Box>
		</Dialog>
	)
}

export default DateTimeNewFormationModal
