export const shortenName = (name: string): string => {
  if (!name) return;

  if (name.length > 20) {
    return `${name.slice(0, 20)}...`;
  }

  return name;
};


export const shortenNameList = (names: string[], maxLength: number = 25): string => {
  if (!names || names.length === 0) return '';

  let result = '';
  let totalLength = 0;

  for (let i = 0; i < names.length; i++) {
    const name = names[i];

    const nameLength = name.length + (i > 0 ? 2 : 0);

    if (totalLength + nameLength > maxLength) {
      result += '...';
      break;
    }

    result += (i > 0 ? ', ' : '') + name;

    totalLength += nameLength;
  }

  return result;
};

