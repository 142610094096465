import {
	createContext,
	useState,
	useCallback,
	useContext,
	ReactNode,
	FC,
} from 'react'
import { UserData } from '../v1/types/types.d'

type Props = {
	children: ReactNode
}

export const AuthContext = createContext({
	user: {
		id: '',
		email: '',
		firstName: '',
		lastName: '',
		clientCode: '',
		deploymentGroup: {
			name: '',
			client: '',
			portal: '',
			usage_statistics: '',
			api: '',
		},
		userVersion: '',
	},
	addUser: (
		id: string,
		email: string,
		firstName: string,
		lastName: string,
		clientCode: string,
		deploymentGroup: UserData['deploymentGroup'],
		userVersion?: string
	) => {},
	removeUser: () => {},
})

export const AuthContextProvider: FC<Props> = ({ children }): JSX.Element => {
	const [user, setUser] = useState({
		id: '',
		email: '',
		firstName: '',
		lastName: '',
		clientCode: '',
		deploymentGroup: {
			name: '',
			client: '',
			portal: '',
			usage_statistics: '',
			api: '',
		},
		userVersion: '',
	})

	const addUser = (
		id: string,
		email: string,
		firstName: string,
		lastName: string,
		clientCode: string,
		deploymentGroup: UserData['deploymentGroup']
	) => {
		const userVersion = determinePathVersion(deploymentGroup.usage_statistics)
		setUser({
			id,
			email,
			firstName,
			lastName,
			clientCode,
			deploymentGroup,
			userVersion,
		})
	}

	const contextValue = {
		user: user,
		addUser: useCallback(
			(
				id: string,
				email: string,
				firstName: string,
				lastName: string,
				clientCode: string,
				deploymentGroup: UserData['deploymentGroup']
			) => addUser(id, email, firstName, lastName, clientCode, deploymentGroup),
			[]
		),
		removeUser: useCallback(
			() =>
				setUser({
					id: '',
					email: '',
					firstName: '',
					lastName: '',
					clientCode: '',
					deploymentGroup: {
						name: '',
						client: '',
						portal: '',
						usage_statistics: '',
						api: '',
					},
					userVersion: '',
				}),
			[]
		),
	}

	return (
		<AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
	)
}

const determinePathVersion = (version: string) => {
	const numVersion = version.split('.')[0]
	return `v${numVersion}`
}

export function useAuthContext() {
	const { user, addUser, removeUser } = useContext(AuthContext)
	return { user, addUser, removeUser }
}
