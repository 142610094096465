import { FC, useContext, useEffect, useReducer } from 'react'
import {
	Table,
	TableBody,
	TableContainer,
	Paper,
	Typography,
	Box,
	Button,
	Checkbox,
	Grid,
} from '@mui/material'
import { CgFileDocument } from 'react-icons/cg'
import {
	actionButtonStyles,
	checkboxGridStyles,
	checkboxTableContainerStyles,
	headerBoxStyles,
	headerTypographyStyles,
	infoGridStyles,
	infoTableContainerStyles,
	tableContainerStyles,
} from '../../../common/theme/ludusTheme.d'
import { LanguageContext } from '../../../context/languageContext'
import Loader from '../common/Loader'
import FilterSection from './common/FilterSection'
import PaginationControls from './common/PaginationControls'
import TableHeader from './common/TableHeader'
import TableRowComponent from './common/TableRow'
import { useTrainings } from '../../hooks/useTrainings'
import { initialState, trainingsReducer } from '../../reducers/trainingsReducer'
import EliminationModal from '../modals/EliminationModal'
import { useFilterContext } from '../../../context/FilterContext'
import { shortenName } from '../../utils/shortenName'

const itemsPerPage = 12

type TrainingTableProps = {
	newTraining: any
	setNewTraining: any
}

const TrainingTable: FC<TrainingTableProps> = ({
	newTraining,
	setNewTraining,
}) => {
	const [state, dispatch] = useReducer(trainingsReducer, initialState)

	const {
		loading,
		error,
		loadedTrainings,
		setLoadedTrainings,
		totalTrainings,
		filteredTrainingsRows,
		setFilteredTrainingsRows,
		deleteTraining,
	} = useTrainings()

	const {
		selectedRows,
		selectAll,
		dialogOpen,
		currentPage,
		filteredDates,
		selectedInstructors,
		selectedSimulations,
		selectedCompanies,
		isTableDarkened,
	} = state

	const {
		dispatch: { translate },
	} = useContext(LanguageContext)

	const { setTrainingsFilters } = useFilterContext()

	useEffect(() => {
		if (newTraining) {
			setLoadedTrainings([...loadedTrainings, newTraining.registerTraining])
			setNewTraining()
		}
	}, [loadedTrainings, newTraining, setLoadedTrainings, setNewTraining])

	useEffect(() => {
		const rows = loadedTrainings.filter((training) =>
			training.TrainingExercises.some(
				(exercise: {
					StartTime: any
					Session: {
						Trainer: { firstName: string; lastName: string }[]
						Product: { Name: string }
					}
					Employee: { Client: { Name: any; ComercialName: any, ClientCode: any } }
				}) => {
					const trainingDate = training.StartTime
					const matchDate = filteredDates
						? trainingDate >= filteredDates[0].getTime() &&
						trainingDate <= new Date(filteredDates[1]).setHours(23, 59, 59, 999)
						: true
					const matchInstructor =
						selectedInstructors.length > 0
							? selectedInstructors.includes(
								shortenName(`${exercise.Session.Trainer[0].firstName} ${exercise.Session.Trainer[0].lastName}`)
							)
							: true
					const matchCompany =
						selectedCompanies.length > 0
							? selectedCompanies.includes(
								shortenName(exercise.Employee?.Client?.Name) ||
								shortenName(exercise.Employee?.Client?.ComercialName) ||
								exercise.Employee?.Client?.ClientCode
							)
							: true
					const matchSimulation =
						selectedSimulations.length > 0
							? selectedSimulations.includes(
								shortenName(translate(`label_product_${exercise.Session.Product.Name}`))
							)
							: true

					return matchDate && matchInstructor && matchCompany && matchSimulation
				}
			)
		)

		const sortedRows = rows.sort(
			(a, b) => new Date(b.StartTime).getTime() - new Date(a.StartTime).getTime()
		)

		const selectedRowsList = []
		const unselectedRowsList = []

		sortedRows.forEach((training) => {
			if (selectedRows.some((selectedTraining) => selectedTraining.TrainingId === training.TrainingId)) {
				selectedRowsList.push(training)
			} else {
				unselectedRowsList.push(training)
			}
		})

		const finalSortedRows = [...selectedRowsList, ...unselectedRowsList]

		setFilteredTrainingsRows(finalSortedRows)

	}, [
		filteredDates,
		selectedInstructors,
		selectedSimulations,
		selectedCompanies,
		selectedRows,
		loadedTrainings,
		setFilteredTrainingsRows,
		translate
	])

	const handleCheckboxChange = (Training: any) => {
		const isSelected = selectedRows.find(
			(selectedTraining) => selectedTraining.TrainingId === Training.TrainingId
		);

		dispatch({
			type: 'SET_SELECTED',
			payload: isSelected
				? selectedRows.filter((selectedTraining) => selectedTraining !== Training)
				: [...selectedRows, Training],
		})
	}

	const handleSelectAllChange = () => {
		const newSelectAll = !selectAll
		const newSelectedRows = newSelectAll
			? [...new Set([...selectedRows, ...paginatedRows])]
			: selectedRows.filter(
				(row) =>
					!paginatedRows.some(
						(paginatedRow) => paginatedRow.TrainingId === row.TrainingId
					)
			)
		dispatch({
			type: 'TOGGLE_SELECT_ALL',
			payload: newSelectedRows,
		})
		dispatch({
			type: 'SET_SELECT_ALL',
			payload: newSelectAll,
		})
	}

	const handleDialogClose = () => {
		dispatch({ type: 'SET_DIALOG_OPEN', payload: false })
	}

	const handleDialogSave = async () => {
		await deleteTraining(selectedRows);

		if (paginatedRows.length === 1 && currentPage > 1) {
			dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 });
		}

		dispatch({ type: 'RESET_SELECTED_ROWS' });
		dispatch({ type: 'SET_DIALOG_OPEN', payload: false });
	};

	const handleResetFilters = () => {
		dispatch({ type: 'RESET_FILTERS' })
		setTrainingsFilters({
			trainer: [],
			company: [],
			product: [],
			date: [],
		})
	}

	const handlePrevPage = () => {
		if (currentPage > 1) {
			dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 })
		}
	}

	const handleNextPage = () => {
		if (currentPage < Math.ceil(filteredTrainingsRows.length / itemsPerPage)) {
			dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage + 1 })
		}
	}

	const handleIsTableDarkenedChange = (isDarkened: boolean) => {
		dispatch({ type: 'SET_IS_TABLE_DARKENED', payload: isDarkened })
	}

	const instructors = Array.from(
		new Map(
			loadedTrainings.flatMap((training) =>
				training.TrainingExercises.map(
					(exercise: {
						Session: { Trainer: { id: any; firstName: string; lastName: string }[] }
					}) => [
							exercise.Session.Trainer[0].id,
							{
								id: exercise.Session.Trainer[0].id,
								name: `${exercise.Session.Trainer[0].firstName} ${exercise.Session.Trainer[0].lastName}`,
							},
						]
				)
			)
		).values()
	)

	const companies = Array.from(
		new Map(
			loadedTrainings.flatMap((training) =>
				training.TrainingExercises.map(
					(exercise: { Employee: { Client: { ClientCode: any; Name: any } } }) => [
						exercise.Employee?.Client?.ClientCode,
						{
							id: exercise.Employee?.Client?.ClientCode,
							name: exercise.Employee?.Client?.Name,
						},
					]
				)
			)
		).values()
	)
	const simulations = Array.from(
		new Map(
			loadedTrainings.flatMap((training) =>
				training.TrainingExercises.map(
					(exercise: { Session: { Product: { ProductId: any; Name: any } } }) => [
						exercise.Session.Product.ProductId,
						{
							id: exercise.Session.Product.ProductId,
							name: exercise.Session.Product.Name,
						},
					]
				)
			)
		).values()
	)

	const paginatedRows = filteredTrainingsRows.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	)

	useEffect(() => {
		const allSelected =
			paginatedRows.length > 0 &&
			paginatedRows.every((row) =>
				selectedRows.some(
					(selectedRow) => selectedRow.TrainingId === row.TrainingId
				)
			)
		if (selectAll !== allSelected) {
			dispatch({ type: 'SET_SELECT_ALL', payload: allSelected })
		}
	}, [currentPage, paginatedRows, selectedRows])

	if (loading) return <Loader />
	if (error) return <p>Error: {error.message}</p>

	return (
		<Box
			sx={{ position: 'relative' }}
			onClick={() => handleIsTableDarkenedChange(false)}
		>
			<TableContainer sx={tableContainerStyles}>
				<Box sx={headerBoxStyles}>
					<Typography variant="h5" sx={headerTypographyStyles}>
						{translate('title_table_trainings')}
					</Typography>
					<Button
						variant="contained"
						startIcon={<CgFileDocument />}
						sx={{
							...actionButtonStyles,
							'&:hover': { backgroundColor: '#003e47' },
							visibility: selectedRows.length > 0 ? 'visible' : 'hidden',
						}}
						onClick={() => dispatch({ type: 'SET_DIALOG_OPEN', payload: true })}
					>
						{translate('button_delete_trainings')}
					</Button>
				</Box>

				{totalTrainings === 0 ? (
					<Box sx={{ padding: '50px', textAlign: 'center', color: 'white' }}>
						<Typography variant="h4" sx={{ margin: '30px', fontWeight: 'bold' }}>
							{translate('title_no_data_trainings')}
						</Typography>
						<Typography variant="h5" sx={{ marginBottom: '60px' }}>
							{translate('text_no_data_trainings_pt1')} <br />
							{translate('text_no_data_trainings_pt2')}
						</Typography>
					</Box>
				) : (
					<Grid container>
						<FilterSection
							tableId="trainings"
							translate={translate}
							filters={['trainer', 'company', 'product', 'date']}
							onResetFilters={handleResetFilters}
							instructors={instructors}
							companies={companies}
							simulations={simulations}
							handleInstructorFilterApply={(instructors: string[]) =>
								dispatch({ type: 'SET_SELECTED_INSTRUCTORS', payload: instructors })
							}
							handleCompanyFilterApply={(companies: string[]) =>
								dispatch({ type: 'SET_SELECTED_COMPANIES', payload: companies })
							}
							handleSimulationFilterApply={(simulations: string[]) =>
								dispatch({ type: 'SET_SELECTED_SIMULATIONS', payload: simulations })
							}
							handlePeriodFilterApply={(dates: [Date, Date] | null) =>
								dispatch({ type: 'SET_FILTERED_DATES', payload: dates })
							}
							onIsTableDarkenedChange={handleIsTableDarkenedChange}
							onPageReset={() => dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 })}
							state={state}
						/>
						{paginatedRows.length === 0 ? (
							<Grid sx={infoGridStyles}>
								<Box sx={{ padding: '50px', textAlign: 'center', color: 'white' }}>
									<Typography variant="h4" sx={{ margin: '30px', fontWeight: 'bold' }}>
										{translate('title_no_data_trainings')}
									</Typography>
									<Typography variant="h5" sx={{ marginBottom: '60px' }}>
										{translate('text_no_data_trainings_pt1')} <br />
										{translate('text_no_data_trainings_pt2')}
									</Typography>
								</Box>
							</Grid>
						) : (
							<>
								<Grid sx={checkboxGridStyles}>
									<TableContainer
										component={Paper}
										sx={{
											...checkboxTableContainerStyles,
											...(isTableDarkened && {
												opacity: 0.3,
												pointerEvents: 'none',
											}),
										}}
									>
										<Table
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												gap: {
													xxs: '3.5px',
													xs: '2px',
													sm: '2px',
													md: '2.5px',
													lg: '6.5px',
													xl: '7px',
												},
												'& .MuiTableCell-root': {
													color: 'white',
												},
											}}
										>
											<Checkbox
												sx={{ color: 'white', transform: 'scale(0.9)' }}
												checked={selectAll}
												onChange={handleSelectAllChange}
											/>
											{paginatedRows.map((row: any) => (
												<Checkbox
													key={row.TrainingId}
													sx={{ color: 'white', transform: 'scale(0.9)' }}
													checked={selectedRows.some(
														(selectedTraining) =>
															selectedTraining.TrainingId === row.TrainingId
													)}
													onChange={() => {
														handleCheckboxChange(row)
													}}
												/>
											))}
										</Table>
									</TableContainer>
								</Grid>

								<Grid sx={infoGridStyles}>
									<TableContainer
										component={Paper}
										sx={{
											minWidth: {
												xxs: 700,
												xs: 700,
												sm: 700,
												md: 520,
												lg: 600,
												xl: 650,
											},
											...infoTableContainerStyles,
											...(isTableDarkened && {
												opacity: 0.3,
												pointerEvents: 'none',
												whiteSpace: 'nowrap',
											}),
										}}
									>
										<Table
											sx={{
												'& .MuiTableCell-root': {
													color: 'white',
													padding: '10px 0px',
													borderRight: '2px solid white',
												},
												'& .MuiTableCell-head': {
													fontWeight: 'bold',
												},
											}}
										>
											<TableHeader
												translate={translate}
												headerKeyIds={[
													'column_table_training',
													'column_table_date',
													'column_table_duration',
													'column_table_trainer',
													'column_table_completion_percentage',
													'column_table_exercise_count',
												]}
											/>
											<TableBody>
												{paginatedRows.map((row: any) => (
													<TableRowComponent
														key={row.TrainingId}
														row={row}
														selected={selectedRows}
														parentTable={'trainings'}
														id={row.TrainingId}
														translate={translate}
													/>
												))}
											</TableBody>
										</Table>
									</TableContainer>
									<PaginationControls
										currentPage={currentPage}
										totalPages={Math.ceil(filteredTrainingsRows.length / itemsPerPage)}
										onPrevPage={handlePrevPage}
										onNextPage={handleNextPage}
									/>
								</Grid>
							</>
						)}
						<EliminationModal
							open={dialogOpen}
							selectedRows={selectedRows}
							onClose={handleDialogClose}
							onConfirm={handleDialogSave}
						/>
					</Grid>
				)}
			</TableContainer>
		</Box>
	)
}

export default TrainingTable
