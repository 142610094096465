import React, { useState, useCallback, useContext, ReactNode, createContext, FC } from 'react';

type Props = {
  children: ReactNode;
};

export const SuccessMessageContext = createContext({
  success: { message: '' },
  addSuccess: (message: string) => {},
  removeSuccess: () => {},
});

export const SuccessMessageContextProvider: FC<Props> = ({ children }): JSX.Element => {
  const [success, setSuccess] = useState({ message: '' });

  const removeSuccess = () => {
    setSuccess({ message: '' });
  };

  const addSuccess = (message: string) => {
    setSuccess({ message });
  };

  const contextValue = {
    success,
    addSuccess: useCallback((message: string) => addSuccess(message), []),
    removeSuccess: useCallback(() => removeSuccess(), []),
  };

  return (
    <SuccessMessageContext.Provider value={contextValue}>
      {children}
    </SuccessMessageContext.Provider>
  );
};

export function useSuccessMessageContext() {
  const { success, addSuccess, removeSuccess } = useContext(SuccessMessageContext);
  return { success, addSuccess, removeSuccess };
}
