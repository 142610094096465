import { Button } from '@mui/material';
import { LanguageContext } from '../../../context/languageContext';
import { useContext } from 'react';
import { FaArrowLeft } from "react-icons/fa6";
import { useVersion } from '../../../context/appVersionContext';
import { useNavigation } from '../../../context/navigationContext';


const BackButton = () => {
    const { appVersion } = useVersion()
    const { navigateToStatistics } = useNavigation()

    const {
        state: { language },
        dispatch: { translate },
    } = useContext(LanguageContext)

    const handleBackClick = () => {
        return navigateToStatistics(appVersion.path, language)
    };

    return (
        <Button
            onClick={handleBackClick}
            startIcon={<FaArrowLeft />}
            sx={{
                fontFamily: 'Barlow Condensed',
                fontSize: { xs: '10px', sm: '10px', md: '12px', lg: "14px", xl: "18px" },
                fontWeight: '400',
                borderRadius: '10px',
                color: '#6C9296',
                backgroundColor: 'transparent',
                '&:hover': {
                    color: 'white',
                    backgroundColor: 'transparent !important',
                },
                textTransform: 'none',
            }}
        >
            {translate("button_back")}
        </Button>
    );
};

export default BackButton;
