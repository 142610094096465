import { FC } from 'react'
import { TableRow, TableCell } from '@mui/material'
import { formatDateTraining } from '../../../utils/formatDate'
import { tdTableStyles } from '../../../../common/theme/ludusTheme.d'

type ActivityRowProps = {
	row: any
	translate: (key: string) => string
}
const ActivityRow: FC<ActivityRowProps> = ({ row, translate }) => {
	const activityResult = row.Passed
		? translate('column_table_completed')
		: translate('column_table_not_completed')
	return (
		<TableRow key={row.ActivityId} sx={tdTableStyles}>
			<TableCell>{formatDateTraining(row.StartTime)}</TableCell>
			<TableCell>{`${row.UserEmail}`}</TableCell>
			<TableCell>{translate(`label_product_${row.Session.Product.Name}`)}</TableCell>
			{/* <TableCell>{row.Name}</TableCell> */}
			<TableCell>{activityResult}</TableCell>
		</TableRow>
	)
}

export default ActivityRow
