import { gql } from '@apollo/client';

export const REGISTER_TRAINING = gql`
  mutation RegisterTraining($input: RegisterTrainingInput!) {
    registerTraining(input: $input) {
        TrainingId
        Name
        StartTime
        EndTime
        UserId
        TrainingExercises {
            ExerciseId
            Name
            Passed
            StartTime
            EndTime
            Session {
                Product {
                    Name
                    ProductId
                }
                Trainer {
                    id
                    firstName
                    lastName
                }
            }
            Employee {
                Client {
                    Name
                    ClientCode
                }
            }
        }
    }
  }
`;

export const DELETER_TRAINING = gql`
  mutation DeleterterTraining($input: DeleterTrainingInput!) {
    deleterTraining(input: $input) {
      deletedRows
     }
  }
`;

