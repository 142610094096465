import { useContext, useState, useEffect } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import {
	genericModalBoxStyles,
	textFieldModalTimeStyles,
} from '../../../common/theme/ludusTheme.d'
import { LanguageContext } from '../../../context/languageContext'

export type TimeModalProps = {
	onClose?: () => void
	onFilterApply: (time: string) => void
	initialTime: string
}

const TimeModal = ({ onFilterApply, initialTime }: TimeModalProps) => {
	const [time, setTime] = useState(initialTime)
	const {
		dispatch: { translate },
	} = useContext(LanguageContext)

	useEffect(() => {
		setTime(initialTime)
	}, [initialTime])

	return (
		<Box sx={genericModalBoxStyles}>
			<Box sx={{ padding: '0px' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
					>
						<Typography variant="h6" sx={{ marginBottom: '18px', color: '#707070' }}>
							{translate('text_dropdown_select_time')}
						</Typography>
						<TextField
							type="time"
							value={time}
							onChange={(e) => {
								setTime(e.target.value)
								onFilterApply(e.target.value)
							}}
							variant="standard"
							InputProps={{
								disableUnderline: true,
								sx: {
									...textFieldModalTimeStyles,
									'& input': {
										textAlign: 'center',
									},
									'& input::-webkit-clear-button, & input::-webkit-inner-spin-button, & input::-webkit-calendar-picker-indicator':
									{
										display: 'none',
									},
								},
							}}
						/>
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						alignItems: 'center',
						marginBottom: '10px',
					}}
				></Box>
			</Box>
		</Box>
	)
}

export default TimeModal
