import { useState, useEffect, useCallback } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { GET_EXERCISES } from '../services/exercisesQueries'
import { REGISTER_TRAINING } from '../services/trainingsMutations'
import { useAuthContext } from '../../context/authContext'
import { fomatDateTime } from '../utils/formatDate'

const loadPerCall = 60

export const useExercises = () => {
	const [loadedExercises, setLoadedExercises] = useState<any[]>([])
	const [totalExercises, setTotalExercises] = useState<number>(0)
	const [remainingExercises, setRemainingExercises] = useState<number>(0)
	const [loadingMore, setLoadingMore] = useState<boolean>(false)
	const [filteredRows, setFilteredRows] = useState<any[]>([])
	const { user } = useAuthContext()

	const { loading, error, fetchMore } = useQuery(GET_EXERCISES, {
		variables: {
			ClientCode: user.clientCode,
			limit: loadPerCall,
			offset: 0,
		},
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'cache-first',
		onCompleted: (data) => {
			setLoadedExercises(data.Exercises.exercises)
			setTotalExercises(data.Exercises.total)
			setRemainingExercises(data.Exercises.remaining)
		},
	})

	const [registerTraining, { data, error: mutationError }] =
		useMutation(REGISTER_TRAINING)

	const loadMoreData = useCallback(async () => {
		if (loadingMore || remainingExercises <= 0) return
		setLoadingMore(true)

		try {
			const { data } = await fetchMore({
				variables: {
					ClientCode: user.clientCode,
					limit: loadPerCall,
					offset: totalExercises - remainingExercises,
				},
			})

			setLoadedExercises((prev) => [...prev, ...data.Exercises.exercises])
			setFilteredRows((prev) => [...prev, ...data.Exercises.exercises])
			setRemainingExercises(data.Exercises.remaining)
		} finally {
			setLoadingMore(false)
		}
	}, [
		loadingMore,
		remainingExercises,
		totalExercises,
		fetchMore,
		user.clientCode,
	])

	const registerNewTraining = async (
		newFormationName,
		startDate,
		endDate: Date,
		startTime: string,
		endTime: string,
		selectedRows: any[],
		onAddFormation: any
	) => {
		try {
			
			const formattedStartTime = fomatDateTime(startDate, startTime).toString();
			const formattedEndTime = fomatDateTime(endDate, endTime).toString();

			await registerTraining({
				variables: {
					input: {
						UserId: `${user.id}`,
						Name: newFormationName,
						ExerciseId: selectedRows.map((exercise) => exercise.ExerciseId),
						StartTime: formattedStartTime,
						EndTime: formattedEndTime,
					},
				},
				onCompleted: (data) => {
					onAddFormation(data)
				},
				onError: (error) => {
					console.error(mutationError)
					console.error('onError option:', { error })
				},
			})
		} catch (error) {
			console.error(error)
			console.error({ mutationError })
		}
	}

	useEffect(() => {
		if (remainingExercises > 0 && loadedExercises.length < totalExercises) {
			loadMoreData()
		}
	}, [loadedExercises, remainingExercises, totalExercises, loadMoreData])

	return {
		loading,
		error,
		loadedExercises,
		totalExercises,
		filteredRows,
		setFilteredRows,
		loadMoreData,
		registerNewTraining,
		mutationError,
	}
}
