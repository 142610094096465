import { FC } from 'react';
import { IconButton, SxProps } from '@mui/material';
import { IoMdClose } from 'react-icons/io';

type CloseModalButtonProps = {
    onClick: () => void;
    sx?: SxProps;
}

const CloseModalButton: FC<CloseModalButtonProps> = ({ onClick, sx }) => (
    <IconButton
        onClick={onClick}
        sx={{
            backgroundColor: '#002B31',
            color: "#5D8A8E",
            '&:hover': { backgroundColor: '#003e47', color: 'white' },
            ...sx
        }}
    >
        <IoMdClose size={20} />
    </IconButton>
);

export default CloseModalButton;
