import { FC, useContext, useEffect, useState } from 'react'
import { decodeUserToken } from '../utils/decodeUserToken'
import { useNavigation } from '../../context/navigationContext'
import { LanguageContext } from '../../context/languageContext'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '../../context/authContext'
import { loginUserOnline } from '../services/userLogin'
import { decodeJwt } from '../utils/JWTE'
import versionConverter from '../utils/versionConverter'
import { useErrorMessageContext } from '../../context/errorMessageContext'
import { useSuccessMessageContext } from '../../context/successMessageContext'
import Loader from '../components/common/Loader'

const Login: FC = () => {
	const { addUser, user } = useAuthContext()
	const { navigateToStatistics } = useNavigation()
	const { jwe } = useParams()
	const [accessToken, setAccessToken] = useState<string | null>(null)
	const {
		state: { language },
		dispatch: { translate },
	} = useContext(LanguageContext)
	const { addError } = useErrorMessageContext()
	const { addSuccess } = useSuccessMessageContext()

	useEffect(() => {
		const fetchUserData = async () => {
			if (jwe === null) return
			const result = await decodeUserToken(jwe)
			if (result.status === 200) {
				if (result.data.accessToken) {
					window.sessionStorage.setItem('accessToken', result.data.accessToken)
					setAccessToken(result.data.accessToken)
				}
			} else {
				addError(result.error as string)
			}
		}
		fetchUserData()
	}, [jwe, language])

	useEffect(() => {
		const autologin = async () => {
			if (!accessToken || accessToken === null) return
			const loginRequest = await loginUserOnline(accessToken)
			const userData: {
				id: string
				email: string
				firstName: string
				lastName: string
				clientCode: string
				deploymentGroup: {
					name: string
					client: string
					portal: string
					usage_statistics: string
					api: string
				}
			} | null = decodeJwt(loginRequest.data?.userToken)

			if (userData === null) {
				addError(translate('error_undefined'))
			}

			const { id, email, firstName, lastName, clientCode, deploymentGroup } =
				userData
			addSuccess(translate('title_welcome'))
			return addUser(id, email, firstName, lastName, clientCode, deploymentGroup)
		}
		autologin()
	}, [accessToken])

	useEffect(() => {
		if (user.id !== '' && user.deploymentGroup.usage_statistics !== '') {
			const versionFormatted = versionConverter(
				user.deploymentGroup.usage_statistics
			)
			navigateToStatistics(versionFormatted, language)
		}
	}, [language, navigateToStatistics, user])

	return (
		<>
			<Loader />
		</>
	)
}

export default Login
