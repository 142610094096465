import { FC } from 'react';
import { Box } from '@mui/material';
import FadeLoader from 'react-spinners/FadeLoader';
import ludusTheme from '../../../common/theme/ludusTheme.d';

const Loader: FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh'
            }}
        >
            <FadeLoader color={ludusTheme.palette.primary.main} />
        </Box>
    );
}

export default Loader;
