import { FC, useState, MouseEvent } from 'react'
import { Box, Typography, Popover } from '@mui/material'
import FilterButton from '../../buttons/FilterButton'
import { IoCalendar } from 'react-icons/io5'
import { PiBuildingApartment } from 'react-icons/pi'
import { LuFilterX } from 'react-icons/lu'
import instructorIcon from '../../../images/Instructor_Icon.png'
import instructorIconGreen from '../../../images/Instructor_Icon_Green.png'
import simulationIcon from '../../../images/Simulation_Icon.png'
import simulationIconGreen from '../../../images/Simulation_Icon_Green.png'
import InstructorModal from '../../modals/InstructorModal'
import CompanyModal from '../../modals/CompanyModal'
import SimulationModal from '../../modals/SimulationModal'
import PeriodModal from '../../modals/PeriodModal'
import {
	boxFilterButtonRemove,
	boxFiltersButtons,
	filterBoxStyles,
	filterText,
	popoverStyles,
} from '../../../../common/theme/ludusTheme.d'

type FilterType = 'trainer' | 'company' | 'product' | 'date'
type TableId = 'exercises' | 'trainings' | 'assessments'
interface FilterSectionProps {
	translate: (key: string) => string
	onResetFilters: () => void
	filters: FilterType[]
	instructors?: any[]
	companies?: any[]
	simulations?: any[]
	handleInstructorFilterApply?: (selectedInstructorNames: string[]) => void
	handleCompanyFilterApply?: (selectedCompanyNames: string[]) => void
	handleSimulationFilterApply?: (selectedSimulationNames: string[]) => void
	handlePeriodFilterApply?: (selectedDates: [Date, Date]) => void
	onIsTableDarkenedChange?: (isDarkened: boolean) => void
	tableId: TableId
	onPageReset: () => void
	state: {
		selectedInstructors: string[],
		selectedCompanies: string[],
		selectedSimulations: string[],
		filteredDates: [Date, Date]
	}
}

const FilterSection: FC<FilterSectionProps> = ({
	translate,
	onResetFilters,
	filters,
	instructors,
	companies,
	simulations,
	handleInstructorFilterApply,
	handleCompanyFilterApply,
	handleSimulationFilterApply,
	handlePeriodFilterApply,
	onIsTableDarkenedChange,
	tableId,
	onPageReset,
	state
}) => {
	const [selectedFilter, setSelectedFilter] = useState<string>('')
	const [instructorPopoverAnchor, setInstructorPopoverAnchor] =
		useState<HTMLElement | null>(null)
	const [companyPopoverAnchor, setCompanyPopoverAnchor] =
		useState<HTMLElement | null>(null)
	const [simulationPopoverAnchor, setSimulationPopoverAnchor] =
		useState<HTMLElement | null>(null)
	const [datePopoverAnchor, setDatePopoverAnchor] = useState<HTMLElement | null>(
		null
	)
	const {
		selectedInstructors,
		selectedCompanies,
		selectedSimulations,
		filteredDates,
	} = state

	const isFilterActive = (filter: FilterType) => {
		switch (filter) {
			case 'trainer':
				return selectedInstructors.length > 0;
			case 'company':
				return selectedCompanies.length > 0;
			case 'product':
				return selectedSimulations.length > 0;
			case 'date':
				return filteredDates && filteredDates.length === 2;
			default:
				return false;
		}
	}

	const handleFilterClick = (
		filter: 'trainer' | 'company' | 'product' | 'date',
		event: MouseEvent<HTMLElement>
	) => {
		event.stopPropagation()
		const filterActions: { [key: string]: () => void } = {
			trainer: () => {
				setInstructorPopoverAnchor(event.currentTarget)
				onIsTableDarkenedChange(true)
			},
			company: () => {
				setCompanyPopoverAnchor(event.currentTarget)
				setInstructorPopoverAnchor(null)
				onIsTableDarkenedChange(true)
			},
			product: () => {
				setSimulationPopoverAnchor(event.currentTarget)
				setInstructorPopoverAnchor(null)
				setCompanyPopoverAnchor(null)
				onIsTableDarkenedChange(true)
			},
			date: () => {
				setDatePopoverAnchor(event.currentTarget)
				setInstructorPopoverAnchor(null)
				setCompanyPopoverAnchor(null)
				setSimulationPopoverAnchor(null)
				onIsTableDarkenedChange(true)
			},
			default: () => {
				setInstructorPopoverAnchor(null)
				setCompanyPopoverAnchor(null)
				setSimulationPopoverAnchor(null)
				setDatePopoverAnchor(null)
				onIsTableDarkenedChange(false)
			},
		}

		setSelectedFilter(filter)
			; (filterActions[filter] || filterActions.default)()
	}

	const handleOutsideClick = () => {
		setSelectedFilter('')
		onIsTableDarkenedChange(false)
	}

	const handleInstructorClose = () => {
		setInstructorPopoverAnchor(null)
		onIsTableDarkenedChange(false)
	}

	const handleCompanyClose = () => {
		setCompanyPopoverAnchor(null)
		onIsTableDarkenedChange(false)
	}

	const handleSimulationClose = () => {
		setSimulationPopoverAnchor(null)
		onIsTableDarkenedChange(false)
	}

	const handleDateClose = () => {
		setDatePopoverAnchor(null)
		onIsTableDarkenedChange(false)
	}

	const handleResetFilters = () => {
		onResetFilters()
		setSelectedFilter('')
	}

	return (
		<Box sx={filterBoxStyles} onClick={(e) => e.stopPropagation()}>
			<Typography variant="body1" sx={filterText}>
				{translate('text_filter_by')}
			</Typography>
			<Box sx={boxFiltersButtons}>
				{filters.includes('trainer') && (
					<FilterButton
						startIcon={
							<Box
								component="img"
								src={isFilterActive('trainer') ? instructorIcon : instructorIconGreen}
								alt="Logo"
								sx={{
									width: 20,
									height: 25,
								}}
							/>
						}
						onClick={(event) => handleFilterClick('trainer', event)}
						selected={isFilterActive('trainer')}
					>
						{translate('column_table_trainer')} {selectedInstructors.length > 0 && `(${selectedInstructors.length})`}
					</FilterButton>
				)}

				{filters.includes('company') && (
					<FilterButton
						startIcon={<PiBuildingApartment size={20} />}
						onClick={(event) => handleFilterClick('company', event)}
						selected={isFilterActive('company')}
					>
						{translate('column_table_company_filter')} {selectedCompanies.length > 0 && `(${selectedCompanies.length})`}
					</FilterButton>
				)}

				{filters.includes('product') && (
					<FilterButton
						startIcon={
							<Box
								component="img"
								src={isFilterActive('product') ? simulationIcon : simulationIconGreen}
								alt="Logo"
								sx={{
									width: 20,
									height: 25,
								}}
							/>
						}
						onClick={(event) => handleFilterClick('product', event)}
						selected={isFilterActive('product')}
					>
						{translate('column_table_simulation_filter')} {selectedSimulations.length > 0 && `(${selectedSimulations.length})`}
					</FilterButton>
				)}

				{filters.includes('date') && (
					<FilterButton
						startIcon={<IoCalendar size={20} />}
						onClick={(event) => handleFilterClick('date', event)}
						selected={isFilterActive('date')}
					>
						{filteredDates && filteredDates.length === 2
							? `${filteredDates[0].toLocaleDateString()} - ${filteredDates[1].toLocaleDateString()}`
							: translate('button_filter_period')}
					</FilterButton>
				)}
			</Box>
			<Box sx={boxFilterButtonRemove}>
				<FilterButton startIcon={<LuFilterX size={20} />} onClick={handleResetFilters}>
					{translate('button_filter_remove')}
				</FilterButton>
			</Box>
			<Popover
				open={Boolean(instructorPopoverAnchor)}
				anchorEl={instructorPopoverAnchor}
				onClose={handleInstructorClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				slotProps={{
					paper: {
						sx: popoverStyles,
					},
				}}
			>
				<Box onClick={(e) => e.stopPropagation()}>
					<InstructorModal
						tableId={tableId}
						onClose={handleInstructorClose}
						onFilterApply={handleInstructorFilterApply}
						instructors={instructors}
						onPageReset={onPageReset}
					/>
				</Box>
			</Popover>
			<Popover
				open={Boolean(companyPopoverAnchor)}
				anchorEl={companyPopoverAnchor}
				onClose={handleCompanyClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				slotProps={{
					paper: {
						sx: popoverStyles,
					},
				}}
			>
				<Box onClick={(e) => e.stopPropagation()}>
					<CompanyModal
						tableId={tableId}
						onClose={handleCompanyClose}
						onFilterApply={handleCompanyFilterApply}
						companies={companies}
						onPageReset={onPageReset}
					/>
				</Box>
			</Popover>
			<Popover
				open={Boolean(simulationPopoverAnchor)}
				anchorEl={simulationPopoverAnchor}
				onClose={handleSimulationClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				slotProps={{
					paper: {
						sx: popoverStyles,
					},
				}}
			>
				<Box onClick={(e) => e.stopPropagation()}>
					<SimulationModal
						tableId={tableId}
						onClose={handleSimulationClose}
						onFilterApply={handleSimulationFilterApply}
						simulations={simulations}
						onPageReset={onPageReset}
					/>
				</Box>
			</Popover>
			<Popover
				open={Boolean(datePopoverAnchor)}
				anchorEl={datePopoverAnchor}
				onClose={handleDateClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				slotProps={{
					paper: {
						sx: popoverStyles,
					},
				}}
			>
				<Box onClick={(e) => e.stopPropagation()}>
					<PeriodModal
						tableId={tableId}
						onFilterApply={handlePeriodFilterApply}
						onClose={handleDateClose}
						open={Boolean(datePopoverAnchor)}
						onPageReset={onPageReset}
					/>
				</Box>
			</Popover>
		</Box>
	)
}

export default FilterSection
