import { Box } from '@mui/material'
import ExercisesPerfomedTable from '../tables/ExercisesTable'
import ludusTheme from '../../../common/theme/ludusTheme.d'
import TrainingTable from '../tables/TrainingsTable'
import { useState } from 'react'

const Activity = () => {
	const [newTraining, setNewTraingin] = useState<any>()
	const handleAddFormation = (newFormation: any) => {
		setNewTraingin(newFormation)
	}

	return (
		<>
			<Box
				sx={{
					backgroundColor: ludusTheme.palette.secondary.main,
					marginTop: 3,
					padding: {
						xxs: '5px',
						xs: '0px',
						sm: '8px',
						md: '16px',
						lg: '24px',
						xl: '24px'
					},
					borderTopLeftRadius: '0px',
					borderTopRightRadius: '20px',
					borderBottomRightRadius: '20px',
					borderBottomLeftRadius: '20px',
				}}
			>
				<TrainingTable newTraining={newTraining} setNewTraining={setNewTraingin} />

				<ExercisesPerfomedTable onAddFormation={handleAddFormation} />
			</Box>
		</>
	)
}

export default Activity
