import { FC } from 'react'
import { TableRow, TableCell, Button, Box, LinearProgress } from '@mui/material'
import ludusTheme, {
	buttonCourseStyles,
	tdTableStyles,
} from '../../../../common/theme/ludusTheme.d'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../../context/authContext'
import { formatDate, formatDuration } from '../../../utils/formatDate'
import { shortenName, shortenNameList } from '../../../utils/shortenName'

type TrainingRowProps = {
	row: any
	selected: any[]
	translate: (key: string) => string
}

const TrainingRow: FC<TrainingRowProps> = ({ row, selected, translate }) => {
	const { user } = useAuthContext()
	const navigate = useNavigate()
	const handleCourseClick = (course) => {
		navigate(`/${user.userVersion}/training/${encodeURIComponent(course)}`, {
			replace: true,
		})
	}

	const trainingDuration = row.TrainingExercises?.reduce(
		(total: number, exercise: { EndTime: number; StartTime: number }) =>
			total + (exercise.EndTime - exercise.StartTime),
		0
	)
	const trainingInstructors = row.TrainingExercises?.flatMap(
		(exercise: { Session: { Trainer: { firstName: any; lastName: any }[] } }) =>
			exercise.Session.Trainer.map(
				(trainer: { firstName: any; lastName: any }) =>
					`${trainer.firstName} ${trainer.lastName}`
			)
	)

	const shortenedInstructors = shortenNameList(Array.from(new Set(trainingInstructors)), 25);

	//! TEMPORAL A LA ESPERA DE QUE PCVR METAN EN AZURE LA COLUMNA CONCLUSION
	const trainingCompletedExercises = 100

	return (
		<TableRow
			key={row.TrainingId}
			sx={{
				backgroundColor: selected.some(
					(selectedTraining) => selectedTraining.TrainingId === row.TrainingId
				)
					? '#006C52'
					: 'inherit',
				'&:hover': {
					backgroundColor: selected.some(
						(selectedTraining) => selectedTraining.TrainingId === row.TrainingId
					)
						? '#006C52'
						: '#555',
				},
				...tdTableStyles,
			}}
		>
			<TableCell>
				<Button
					sx={buttonCourseStyles}
					onClick={() => handleCourseClick(row.TrainingId)}
				>
					{shortenName(row.Name)}
				</Button>
			</TableCell>
			<TableCell>{`${formatDate(row.StartTime)} - ${formatDate(row.EndTime)}`}</TableCell>
			<TableCell>{formatDuration(trainingDuration)}</TableCell>
			<TableCell>{shortenedInstructors}</TableCell>
			<TableCell sx={{ width: '20%' }}>
				<Box sx={{ position: 'relative', display: 'inline-flex', width: '100%' }}>
					<LinearProgress
						variant="determinate"
						value={trainingCompletedExercises}
						sx={{
							marginRight: 2,
							marginLeft: 2,
							height: 20,
							width: '100%',
							borderRadius: '20px',
							backgroundColor: ludusTheme.palette.primary.dark,
							'& .MuiLinearProgress-bar': {
								backgroundColor: ludusTheme.palette.primary.main,
								borderRadius: '20px',
							},
						}}
					/>
					<Box
						sx={{
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							position: 'absolute',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: 'white',
							fontSize: '1rem',
							fontWeight: 'bold',
						}}
					>
						{`${trainingCompletedExercises}%`}
					</Box>
				</Box>
			</TableCell>
			<TableCell
				sx={{
					fontSize: {
						xs: '10px !important',
						sm: '12px !important',
						md: '14px !important',
						lg: '19px !important',
						xl: '19px !important',
					  },
					fontWeight: 'bold !important',
				}}
			>
				{row.TrainingExercises?.length}
			</TableCell>
		</TableRow>
	)
}

export default TrainingRow
