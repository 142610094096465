interface AssessmentError {
	AssessmentId: string
	Id: number
	ErrorId: string
}

interface AssessmentResult {
	Checklists: { Checked: boolean; ChecklistId: string }[]
	Errors: { ErrorId: string }[]
	Remarks: any[]
}

interface Assessment {
	AssessmentId: string
	Name: string
	IsPassed: boolean
	StartTime: string
	EndTime: string
	ExerciseId: string
	Results: AssessmentResult
	AssessmentErrors: AssessmentError[]
}

interface AssesmentTranslation {
	TranslationId: string | null
	es: string | null
	en: string | null
	eu: string | null
	ca: string | null
	es_lt: string | null
	it: string | null
	pr_br: string | null
	pt: string | null
	de: string | null
	pl: string | null
	nl: string | null
	ms: string | null
	tr: string | null
	fr: string | null
	test: string | null
	Comments: string | null
	vi: string | null
}

interface AssessmentErrorsDescriptions {
	ErrorId: string
	ErrorDescriptionId: string | null
	Translations: AssesmentTranslation
}

interface ProductAssement {
	ProductId: string
	Name: string
	AssessmentErrorsDescriptions: AssessmentErrorsDescriptions[]
}

function calculateErrorPercentage(
	errorId: string,
	assessments: Assessment[]
): number {
	const totalErrors = assessments.reduce((total, assessment) => {
		return total + assessment.Results.Errors.length
	}, 0)

	const errorOccurrences = assessments.filter((assessment) =>
		assessment.AssessmentErrors.some((error) => error.ErrorId === errorId)
	).length
	return (errorOccurrences / totalErrors) * 100
}

export function generateAssessmentResults(
	assessments: Assessment[],
	productsAssements: ProductAssement[]
): { ProductName: string; AssessmentErrorsDescriptions: any[] }[] {
	const results = productsAssements.map((product) => {
		const assessmentErrorsDescriptions = product.AssessmentErrorsDescriptions.map(
			(desc) => ({
				ErrorId: desc.ErrorId,
				Translation: {
					es: desc.Translations.es,
					en: desc.Translations.en,
					eu: desc.Translations.eu,
					ca: desc.Translations.ca,
					es_lt: desc.Translations.es_lt,
					it: desc.Translations.it,
					pr_br: desc.Translations.pr_br,
					pt: desc.Translations.pt,
					de: desc.Translations.de,
					pl: desc.Translations.pl,
					nl: desc.Translations.nl,
					ms: desc.Translations.ms,
					tr: desc.Translations.tr,
					fr: desc.Translations.fr,
					test: desc.Translations.test,
					vi: desc.Translations.vi,
				},
			})
		)

		return {
			ProductId: product.ProductId,
			ProductName: product.Name,
			AssessmentErrorsDescriptions: assessmentErrorsDescriptions.map((desc) => ({
				ErrorId: desc.ErrorId,
				Translation: desc.Translation,
				Percentage: calculateErrorPercentage(desc.ErrorId, assessments),
			})),
		}
	})

	const groupedByProduct = results.reduce((acc, item) => {
		if (!acc[item.ProductName]) {
			acc[item.ProductName] = []
		}
		acc[item.ProductName].push(item)
		return acc
	}, {} as Record<string, any[]>)

	const combinedResults = Object.keys(groupedByProduct).map((ProductName) => {
		const items = groupedByProduct[ProductName]
		const combinedResult = items.reduce((acc, current) => {
			current.AssessmentErrorsDescriptions.forEach((error) => {
				const existingError = acc.find((e) => e.ErrorId === error.ErrorId)
				if (existingError) {
					existingError.Percentage += error.Percentage
				} else {
					acc.push({ ...error })
				}
			})
			return acc
		}, [] as any[])

		const totalPercentage = combinedResult.reduce(
			(sum, item) => sum + item.Percentage,
			0
		)

		if (totalPercentage !== 100) {
			combinedResult.forEach((item) => {
				item.Percentage = (item.Percentage / totalPercentage) * 100
			})
		}

		return {
			ProductName,
			AssessmentErrorsDescriptions: combinedResult,
		}
	})

	return combinedResults
}

export function extractErrorDetails(dataObject) {
	let extractedData = {}
	for (const key in dataObject) {
		extractedData[key] = dataObject[key].map((error) => ({
			ErrorId: error.ErrorId,
			Translation: error.Translation,
			Percentage: error.Percentage,
		}))
	}
	return extractedData
}
