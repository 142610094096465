import { FC, useContext, useState, useRef, useEffect } from 'react'
import { Box, Button, ToggleButtonGroup, ToggleButton } from '@mui/material'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { LanguageContext } from '../../../context/languageContext'
import 'cally'
import '../../../common/daysLocals'
import ludusTheme, {
	calendarStyles,
	calendarDayStyles,
	calendarButtonStyles,
	calendarButtonArrows,
	calendarRangeStyles,
	filterButtonPeriodModalStyles,
	toggleButtonGroupStyles,
	toggleButtonStyles,
	styleBoxPeriodModal,
} from '../../../common/theme/ludusTheme.d'
import FilterApplyButton from '../buttons/FilterApplyButton'
import CloseModalButton from '../buttons/CloseModalButton'
import MonthSlider from './MonthSlider'
import { useFilterContext } from '../../../context/FilterContext'

type PeriodModalProps = {
	onClose: () => void
	onFilterApply: (selectedDates: [Date, Date]) => void
	open: boolean
	tableId: string
	onPageReset: () => void
}

declare global {
	namespace JSX {
		interface IntrinsicElements {
			'calendar-range': any
			'calendar-month': any
		}
	}
}

const PeriodModal: FC<PeriodModalProps> = ({
	onClose,
	onFilterApply,
	open,
	tableId,
	onPageReset
}) => {
	const {
		exercisesFilters,
		setExercisesFilters,
		trainingsFilters,
		setTrainingsFilters,
	} = useFilterContext()
	const [selectedButton, setSelectedButton] = useState<string | null>(null)
	const [view, setView] = useState<'fechas' | 'meses'>('fechas')
	const rangeRef = useRef<HTMLElement>(null)
	const {
		state: { language },
		dispatch: { translate },
	} = useContext(LanguageContext)

	const [isFilterApplyDisabled, setIsFilterApplyDisabled] = useState(true)

	const selectedItems =
		tableId === 'trainings' ? trainingsFilters : exercisesFilters
	const setSelectedItems =
		tableId === 'trainings' ? setTrainingsFilters : setExercisesFilters

	const handleDateChange = (e: Event) => {
		const target = e.target as any
		const value = target._props?.value
		if (value) {
			const [start, end] = value.split('/').map((date: string) => new Date(date))

			if (!isNaN(start.getTime()) && !isNaN(end.getTime())) {
				setSelectedItems((prev) => ({ ...prev, date: [start, end] }))
				setIsFilterApplyDisabled(false)
			} else {
				console.log('Fechas inválidas detectadas:', { start, end })
			}
		} else {
			console.log('No se detectó cambio de fecha.')
		}
	}

	const handleFilterApply = () => {
		onPageReset()
		onFilterApply(selectedItems.date)
		onClose()
	}

	const handleMonthSliderChange = (dates: [Date, Date]) => {
		setSelectedItems((prev) => ({ ...prev, date: dates }))
		setIsFilterApplyDisabled(false)
	}

	useEffect(() => {
		const current = rangeRef.current
		if (current) {
			current.addEventListener('change', handleDateChange)
			return () => {
				current.removeEventListener('change', handleDateChange)
			}
		}
	}, [])

	useEffect(() => {
		console.log(selectedItems.company)
		if (open && rangeRef.current) {
			rangeRef.current.setAttribute(
				'value',
				selectedItems.date.map((date) => date.toISOString().split('T')[0]).join('/')
			)
		} else if (open && selectedItems.date.length === 0) {
			rangeRef.current.setAttribute('value', '')
		}
	}, [open, selectedItems.date])

	const handleButtonClick = (type: string) => {
		setSelectedButton(type)
		let startDate: Date
		const endDate = new Date()
		if (type === '3months') {
			startDate = new Date()
			startDate.setMonth(endDate.getMonth() - 3)
		} else if (type === '1year') {
			startDate = new Date()
			startDate.setFullYear(endDate.getFullYear() - 1)
		} else {
			startDate = new Date('2024-01-01')
		}
		setSelectedItems((prev) => ({ ...prev, date: [startDate, endDate] }))
		setIsFilterApplyDisabled(false)
	}

	return (
		<Box
			sx={styleBoxPeriodModal}
		>
			<Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
				<ToggleButtonGroup
					value={view}
					exclusive
					onChange={(_, newValue) => newValue && setView(newValue)}
					sx={toggleButtonGroupStyles}
				>
					<ToggleButton
						value="fechas"
						sx={{
							...toggleButtonStyles,
							background: view === 'fechas' ? '#FFFFFF !important' : '#BEBEBE',
							fontFamily: 'Barlow Condensed !important',
							fontSize: { xs: 10, sm: 12, md: 12, lg: 14, xl: 16 },
							'&:hover': {
								background: view === 'fechas' ? '#D0D0D0 !important' : '#D0D0D0',
							},
						}}
					>
						{translate('button_dates')}
					</ToggleButton>
					<ToggleButton
						value="meses"
						sx={{
							...toggleButtonStyles,
							background: view === 'meses' ? '#FFFFFF !important' : '#BEBEBE',
							fontFamily: 'Barlow Condensed !important',
							fontSize: { xs: 10, sm: 12, md: 12, lg: 14, xl: 16 },
							'&:hover': {
								background: view === 'meses' ? '#D0D0D0 !important' : '#D0D0D0',
							},
						}}
					>
						{translate('button_months')}
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
			{
				view === 'fechas' ? (
					<>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								mb: 1,
								gap: { xxs: 1, xs: 1, sm: 1, md: 3, lg: 3, xl: 3 },
								flexDirection: { xxs: 'column', xs: 'colum', sm: 'row', md: 'row', lg: 'row', xl: 'row' }
							}}
						>
							<Button
								variant="contained"
								sx={{
									...filterButtonPeriodModalStyles,
									backgroundColor:
										selectedButton === '3months'
											? ludusTheme.palette.primary.main
											: '#BEBEBE',
									color: selectedButton === '3months' ? 'black' : 'white',
									'&:hover': {
										backgroundColor:
											selectedButton === '3months'
												? ludusTheme.palette.primary.main
												: '#cdcdcd',
									},
								}}
								onClick={() => handleButtonClick('3months')}
							>
								{translate('button_last_3_months')}
							</Button>
							<Button
								variant="contained"
								sx={{
									...filterButtonPeriodModalStyles,
									backgroundColor:
										selectedButton === '1year'
											? ludusTheme.palette.primary.main
											: '#BEBEBE',
									color: selectedButton === '1year' ? 'black' : 'white',
									'&:hover': {
										backgroundColor:
											selectedButton === '1year'
												? ludusTheme.palette.primary.main
												: '#cdcdcd',
									},
								}}
								onClick={() => handleButtonClick('1year')}
							>
								{translate('button_last_year')}
							</Button>
							<Button
								variant="contained"
								sx={{
									...filterButtonPeriodModalStyles,
									backgroundColor:
										selectedButton === 'always'
											? ludusTheme.palette.primary.main
											: '#BEBEBE',
									color: selectedButton === 'always' ? 'black' : 'white',
									'&:hover': {
										backgroundColor:
											selectedButton === 'always'
												? ludusTheme.palette.primary.main
												: '#cdcdcd',
									},
								}}
								onClick={() => handleButtonClick('always')}
							>
								{translate('button_always')}
							</Button>
						</Box>
						<calendar-range
							ref={rangeRef as any}
							show-outside-days
							locale={language}
							months={1}
							value={selectedItems.date
								.map((date) => date.toISOString().split('T')[0])
								.join(' to ')}
							style={calendarRangeStyles}
						>
							<style>{calendarButtonStyles}</style>
							<Box slot="previous" sx={calendarButtonArrows}>
								<IoIosArrowBack size={20} />
							</Box>
							<Box slot="next" sx={calendarButtonArrows}>
								<IoIosArrowForward size={20} />
							</Box>
							<Box
								sx={{
									display: 'flex',
									gap: '10px',
									flexWrap: 'wrap',
									justifyContent: 'center',
								}}
							>
								<calendar-month offset="-1" style={calendarStyles}>
									<style>{calendarDayStyles}</style>
								</calendar-month>
								<calendar-month offset="0" style={calendarStyles}>
									<style>{calendarDayStyles}</style>
								</calendar-month>
							</Box>
						</calendar-range>
					</>
				) : (
					<Box sx={{ width: '100%', textAlign: 'center', mb: 2 }}>
						<MonthSlider onDatesChange={handleMonthSliderChange} />
					</Box>
				)
			}
			<Box
				sx={{
					display: 'flex',
					justifyContent: { xxs: 'center', xs: 'center', sm: 'flex-end', md: 'flex-end', lg: 'flex-end', xl: 'flex-end' },
					alignItems: 'center',
					gap: '10px',
					mt: 1,
				}}
			>
				<FilterApplyButton
					onClick={handleFilterApply}
					sx={{ width: '150px !important' }}
					disabled={isFilterApplyDisabled} 
				/>
				<CloseModalButton onClick={onClose} />
			</Box>
		</Box >
	)
}

export default PeriodModal
