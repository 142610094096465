import { FC, useContext } from 'react'
import GenericModal from '../common/GenericModal'
import { LanguageContext } from '../../../context/languageContext'
import { shortenName } from '../../utils/shortenName'

type InstructorModalProps = {
	onClose: () => void
	onFilterApply: (selectedInstructors: string[]) => void
	instructors: { id: string; name: string }[]
	tableId: string
	onPageReset: () => void
}

const InstructorModal: FC<InstructorModalProps> = ({
	tableId,
	onClose,
	onFilterApply,
	instructors,
	onPageReset
}) => {
	const {
		dispatch: { translate },
	} = useContext(LanguageContext)

	const shortenedInstructorNames = instructors.map((instructor) =>
		shortenName(instructor.name)
	)

	return (
		<GenericModal
			tableId={tableId}
			modalType="trainer"
			title={translate('search_trainer')}
			items={shortenedInstructorNames}
			onClose={onClose}
			onFilterApply={onFilterApply}
			onPageReset={onPageReset}
		/>
	)
}

export default InstructorModal
