import { FC, useEffect, useReducer, useState } from 'react'
import { TableContainer, Typography, Box, Grid } from '@mui/material'

import {
	headerBoxStyles,
	headerTypographyStyles,
	infoGridStyles,
	tableContainerStyles,
} from '../../../common/theme/ludusTheme.d'

import CircularProgressLabel from './CircularProgressLabel'
import { generateAssessmentResults } from '../../utils/assessmentCalculator'
import {
	assessmentsReducer,
	initialState,
} from '../../reducers/assessmentsReducer'
import FilterSection from '../tables/common/FilterSection'
import PaginationControls from '../tables/common/PaginationControls'
import { useFilterContext } from '../../../context/FilterContext'
import { shortenName } from '../../utils/shortenName'

const itemsPerPage = 9
type SimulationPanelProps = {
	language: string
	assessmentsErrors: any
	productsAssessments: any
	translate: (key: string) => string
}

const SimulationPanel: FC<SimulationPanelProps> = ({
	assessmentsErrors,
	productsAssessments,
	translate,
	language,
}) => {
	const [filteredAssesmentsRows, setFilteredAssesmentsRows] = useState<any[]>([])
	const [paginatedRows, setPaginatedRows] = useState<any[]>([])
	const { setAssessmentsFilters } = useFilterContext()
	const [state, dispatch] = useReducer(assessmentsReducer, initialState)
	const { currentPage, selectedSimulations, isTableDarkened } = state

	useEffect(() => {
		if (selectedSimulations.length > 0) {
			const filtered = productsAssessments.filter((assessment) => {
				const translatedAndShortenedName = shortenName(translate(`label_product_${assessment.Name}`));
				return selectedSimulations.includes(translatedAndShortenedName);
			});
			setFilteredAssesmentsRows(filtered);
		} else {
			setFilteredAssesmentsRows(productsAssessments);
		}
	}, [productsAssessments, selectedSimulations, translate]);


	const assessmentResult = generateAssessmentResults(
		assessmentsErrors,
		filteredAssesmentsRows
	)

	const totalAssesments = (assessmentResult || []).map(
		(assessment) => assessment.AssessmentErrorsDescriptions || []
	)

	useEffect(() => {
		if (totalAssesments.length > 0 && Array.isArray(totalAssesments[0])) {
			const sortedRows = totalAssesments.flat().sort((a, b) =>
				(b.Percentage || 0) - (a.Percentage || 0)
			);

			const paginated = sortedRows.slice(
				(currentPage - 1) * itemsPerPage,
				currentPage * itemsPerPage
			)
			setPaginatedRows((prevRows) => {
				const prevFirstItem = prevRows[0] ? prevRows[0].ErrorId : null;
				const newFirstItem = paginated[0] ? paginated[0].ErrorId : null;

				if (prevFirstItem !== newFirstItem) {
					return paginated;
				} else {
					return prevRows;
				}
			})
		}
	}, [currentPage, totalAssesments])

	const simulations = Array.from(
		new Map(
			productsAssessments.map((assessment) => [
				assessment.ProductId,
				{ id: assessment.ProductId, name: assessment.Name },
			])
		).values()
	)

	const handleResetFilters = () => {
		dispatch({ type: 'RESET_FILTERS' })
		setAssessmentsFilters({
			trainer: [],
			company: [],
			product: [],
			date: [],
		})
	}

	const handlePrevPage = () => {
		if (currentPage > 1) {
			dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 })
		}
	}

	const handleNextPage = () => {
		if (currentPage < Math.ceil(totalAssesments.flat().length / itemsPerPage)) {
			dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage + 1 })
		}
	}

	const handleIsTableDarkenedChange = (isDarkened: boolean) => {
		dispatch({ type: 'SET_IS_TABLE_DARKENED', payload: isDarkened })
	}

	return (
		<Box sx={{ position: 'relative' }}>
			<TableContainer sx={{ ...tableContainerStyles, minHeight: '100%' }}>
				<Box sx={headerBoxStyles}>
					<Typography variant="h5" sx={headerTypographyStyles}>
						{translate('title_table_simulation_panel')}
					</Typography>
				</Box>
				<Grid container>
					<FilterSection
						tableId="assessments"
						translate={translate}
						filters={['product']}
						onResetFilters={handleResetFilters}
						simulations={simulations}
						handleSimulationFilterApply={(simulations: string[]) =>
							dispatch({ type: 'SET_SELECTED_SIMULATIONS', payload: simulations })
						}
						onIsTableDarkenedChange={handleIsTableDarkenedChange}
						onPageReset={() => dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 })}
						state={state}
					/>
					<Grid container sx={{ ...infoGridStyles, marginInline: 2, marginTop: 2 }}>
						{paginatedRows.map((row, index) => (
							<Grid item xs={6} sm={4} md={4} key={index}>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										...(isTableDarkened && {
											opacity: 0.3,
											pointerEvents: 'none',
										}),
										marginBottom: { xxs: '5px', sm: 0 }
									}}
								>
									<CircularProgressLabel
										value={row.Percentage ? row.Percentage : 0}
									/>
									<Typography
										variant="body2"
										sx={{
											fontSize: {
												xxs: '12px',
												xs: '10px',
												sm: '12px',
												md: '14px',
												lg: '16px',
												xl: '20px',
											},
											marginLeft: 2,
											color: 'white',
										}}
									>
										{row.Translation[language]}
									</Typography>
								</Box>
							</Grid>
						))}
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
							<PaginationControls
								currentPage={currentPage}
								totalPages={Math.ceil(totalAssesments.flat().length / itemsPerPage)}
								onPrevPage={handlePrevPage}
								onNextPage={handleNextPage}
							/>
						</Grid>
					</Grid>
				</Grid>
			</TableContainer>
		</Box >
	)
}

export default SimulationPanel
