import { FC, ReactNode } from 'react';
import { Button, Box, Typography } from '@mui/material';

type SimpleButtonProps = {
    onClick: () => void;
    icon?: ReactNode;
    label: string;
}

const SimpleButton: FC<SimpleButtonProps> = ({ onClick, icon, label }) => (
    <Button
        onClick={onClick}
        variant="contained"
        sx={{
            backgroundColor: '#002B31',
            color: "#5D8A8E",
            fontFamily: 'Barlow Condensed !important',
            fontWeight: '500',
            borderRadius: "25px",
            width: '200px',
            fontSize: { xs: 10, sm: 12, md: 12, lg: 16 },
            '&:hover': { backgroundColor: '#003e47', color: 'white' },
            padding: "5px",
        }}
    >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <Box sx={{ marginRight: 'auto', paddingLeft: '10px' }}>
                {icon}
            </Box>
            <Typography sx={{ flexGrow: 1, textAlign: 'center', paddingRight: '10px' }}>{label}</Typography>
        </Box>
    </Button>
);

export default SimpleButton;
