export const initialState = {
	selectedRows: [] as any[],
	selectAll: false,
	dialogOpen: false,
	currentPage: 1,
	filteredDates: null as [Date, Date] | null,
	selectedInstructors: [] as string[],
	selectedSimulations: [] as string[],
	selectedCompanies: [] as string[],
	isTableDarkened: false,
}

type State = typeof initialState

type Action =
	| { type: 'SET_SELECTED'; payload: any[] }
	| { type: 'TOGGLE_SELECT_ALL'; payload: any[] }
	| { type: 'SET_DIALOG_OPEN'; payload: boolean }
	| { type: 'SET_CURRENT_PAGE'; payload: number }
	| { type: 'SET_FILTERED_DATES'; payload: [Date, Date] | null }
	| { type: 'SET_SELECTED_INSTRUCTORS'; payload: string[] }
	| { type: 'SET_SELECTED_SIMULATIONS'; payload: string[] }
	| { type: 'SET_SELECTED_COMPANIES'; payload: string[] }
	| { type: 'SET_IS_TABLE_DARKENED'; payload: boolean }
	| { type: 'RESET_FILTERS' }

export const assessmentsReducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'SET_SELECTED':
			return { ...state, selectedRows: action.payload }
		case 'TOGGLE_SELECT_ALL':
			return {
				...state,
				selectAll: !state.selectAll,
				selectedRows: action.payload,
			}
		case 'SET_DIALOG_OPEN':
			return { ...state, dialogOpen: action.payload }
		case 'SET_CURRENT_PAGE':
			return { ...state, currentPage: action.payload }
		case 'SET_FILTERED_DATES':
			return { ...state, filteredDates: action.payload }
		case 'SET_SELECTED_INSTRUCTORS':
			return { ...state, selectedInstructors: action.payload }
		case 'SET_SELECTED_SIMULATIONS':
			return { ...state, selectedSimulations: action.payload }
		case 'SET_SELECTED_COMPANIES':
			return { ...state, selectedCompanies: action.payload }
		case 'SET_IS_TABLE_DARKENED':
			return { ...state, isTableDarkened: action.payload }
		case 'RESET_FILTERS':
			return {
				...state,
				filteredDates: null,
				selectedInstructors: [],
				selectedSimulations: [],
				selectedCompanies: [],
				currentPage: 1,
			}
		default:
			return state
	}
}
