import { useState, useEffect, useCallback } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { GET_TRAININGS } from '../services/trainingsQueries'
import { DELETER_TRAINING } from '../services/trainingsMutations'
import { useAuthContext } from '../../context/authContext'
import { fomatDateTime, formatDuration } from '../utils/formatDate'

const loadPerCall = 12

export const useTrainings = () => {
	const [loadedTrainings, setLoadedTrainings] = useState<any[]>([])
	const [totalTrainings, setTotalTrainings] = useState<number>(0)
	const [remainingTrainings, setRemainingTrainings] = useState<number>(0)
	const [loadingMore, setLoadingMore] = useState<boolean>(false)
	const [filteredTrainingsRows, setFilteredTrainingsRows] = useState<any[]>([])
	const [trainingNames, setTrainingNames] = useState<string[]>([])
	const { user } = useAuthContext()

	const { loading, error, fetchMore } = useQuery(GET_TRAININGS, {
		variables: {
			ClientCode: user.clientCode,
			limit: loadPerCall,
			offset: 0,
		},
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'cache-first',
		onCompleted: (data) => {
			setLoadedTrainings(data.Trainings.trainings)
			setTotalTrainings(data.Trainings.total)
			setRemainingTrainings(data.Trainings.remaining)
			const names = data.Trainings.trainings.map((training) => training.Name)
			setTrainingNames(names)
		},
	})
	const loadMoreData = useCallback(async () => {
		if (loadingMore || remainingTrainings <= 0) return
		setLoadingMore(true)

		try {
			const { data } = await fetchMore({
				variables: {
					ClientCode: user.clientCode,
					limit: loadPerCall,
					offset: totalTrainings - remainingTrainings,
				},
			})
			setLoadedTrainings((prev) => [...prev, ...data.Trainings.trainings])
			setFilteredTrainingsRows((prev) => [...prev, ...data.Trainings.trainings])
			setRemainingTrainings(data.Trainings.remaining)
			const names = data.Trainings.trainings.map((training) => training.Name)
			setTrainingNames((prev) => [...prev, ...names])
		} finally {
			setLoadingMore(false)
		}
	}, [
		loadingMore,
		remainingTrainings,
		totalTrainings,
		fetchMore,
		user.clientCode,
	])

	const [deleterTraining, { data, error: mutationError }] =
		useMutation(DELETER_TRAINING)

	const deleteTraining = async (selectedRows: any[]) => {
		try {
			const deletedIds = selectedRows.map((training) => training.TrainingId)
			await deleterTraining({
				variables: {
					input: {
						TrainingId: deletedIds,
					},
				},
				onCompleted: (data) => {
					const filteredTrainings = loadedTrainings.filter(
						(training) => !deletedIds.includes(training.TrainingId)
					)
					setLoadedTrainings(filteredTrainings)
				},
				onError: (error) => {
					console.error(mutationError)
					console.error('onError option:', { error })
				},
			})
		} catch (error) {
			console.error(error)
			console.error({ mutationError })
		}
	}

	useEffect(() => {
		if (remainingTrainings > 0 && loadedTrainings.length < totalTrainings) {
			loadMoreData()
		}
	}, [loadedTrainings, remainingTrainings, totalTrainings, loadMoreData])

	return {
		loading,
		error,
		loadedTrainings,
		setLoadedTrainings,
		totalTrainings,
		filteredTrainingsRows,
		setFilteredTrainingsRows,
		loadMoreData,
		deleteTraining,
		mutationError,
		trainingNames,
	}
}
