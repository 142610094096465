import { FC, useContext, useEffect, useState } from 'react'
import {
	Dialog,
	DialogActions,
	DialogContent,
	Button,
	TextField,
	Box,
	Typography,
	IconButton,
	InputAdornment,
} from '@mui/material'
import { FaRegTrashCan } from 'react-icons/fa6'
import { IoMdClose } from 'react-icons/io'
import { CgFileDocument } from 'react-icons/cg'
import { IoCalendar } from 'react-icons/io5'
import DateTimeNewFormationModal from './DateTimeNewFormationModal'
import { LanguageContext } from '../../../context/languageContext'
import { useErrorMessageContext } from '../../../context/errorMessageContext'
import { formatDate } from '../../utils/formatDate'
import { useTrainings } from '../../hooks/useTrainings'
import { propsNewFormationDialog, styleButtonsCalendar, textfieldNewFormationDialog } from '../../../common/theme/ludusTheme.d'

interface NewFormationDialogProps {
	open: boolean
	selectedRows?: any[]
	onClose: () => void
	onSave: (
		newFormationName: string,
		startDate: Date | null,
		endDate: Date | null,
		startTime: string,
		endTime: string
	) => void
	onDeselectAll?: () => void
}

const NewFormationDialog: FC<NewFormationDialogProps> = ({
	open,
	selectedRows,
	onClose,
	onSave,
	onDeselectAll,
}) => {
	const [newFormationName, setNewFormationName] = useState('')
	const [startDate, setStartDate] = useState<Date | null>(null)
	const [endDate, setEndDate] = useState<Date | null>(null)
	const [startTime, setStartTime] = useState('00:00')
	const [endTime, setEndTime] = useState('00:00')
	const [isDateTimeNewFormationModalOpen, setIsDateTimeNewFormationModalOpen] = useState(false)
	const [dateType, setDateType] = useState<'start' | 'end'>('start')
	const {
		dispatch: { translate },
	} = useContext(LanguageContext)
	const { addError } = useErrorMessageContext()
	const { trainingNames } = useTrainings()

	useEffect(() => {
		if (selectedRows.length > 0) {
			const sortedRows = [...selectedRows].sort(
				(a, b) => Number(a.StartTime) - Number(b.StartTime)
			);
			const earliestStart = new Date(Number(sortedRows[0].StartTime));
			const latestEnd = new Date(Number(sortedRows[sortedRows.length - 1].EndTime));

			setStartDate(earliestStart);
			setEndDate(latestEnd);
			setStartTime(
				earliestStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
			);
			setEndTime(
				latestEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
			);
		}
	}, [selectedRows]);

	const trainingInstructorsNames = selectedRows.flatMap(
		(exercise: { Session: { Trainer: { firstName: any; lastName: any }[] } }) =>
			exercise.Session.Trainer.map(
				(trainer: { firstName: any; lastName: any }) =>
					`${trainer.firstName} ${trainer.lastName}`
			)
	)

	const handleSave = () => {
		if (newFormationName === '' || startDate === null || endDate === null) {
			return addError(translate('error_new_formation_name_empty'))
		}

		if (trainingNames.includes(newFormationName)) {
			return addError(translate('error_new_formation_name_exists'))
		}

		onSave(newFormationName, startDate, endDate, startTime, endTime)
		setNewFormationName('')
		setStartDate(null)
		setEndDate(null)
		setStartTime('00:00')
		setEndTime('00:00')
		onDeselectAll()
		onClose()
	}

	const handleClose = () => {
		setNewFormationName('')
		setStartDate(null)
		setEndDate(null)
		setStartTime('00:00')
		setEndTime('00:00')
		onDeselectAll()
		onClose()
	}

	const handleDateClick = (type: 'start' | 'end') => {
		setDateType(type)
		setIsDateTimeNewFormationModalOpen(true)
	}

	const handleDateTimeNewFormationModalClose = () => {
		setIsDateTimeNewFormationModalOpen(false)
	}

	const handleDateFilterApply = (selectedDate: Date, time: string) => {
		if (dateType === 'start') {
			setStartDate(selectedDate)
			setStartTime(time)
		} else {
			setEndDate(selectedDate)
			setEndTime(time)
		}
		handleDateTimeNewFormationModalClose()
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {...propsNewFormationDialog},
			}}
		>
			<IconButton
				onClick={onClose}
				sx={{
					position: 'absolute',
					top: 8,
					right: 8,
					color: 'white',
					'&:hover': { backgroundColor: '#003e47' },
				}}
			>
				<IoMdClose />
			</IconButton>
			<DialogContent
				sx={{
					backgroundColor: '#002126', color: 'white', position: 'relative',
					padding: { xxs: 0, xs: 0, sm: 0, md: '20px 24px', lg: '20px 24px', xl: '20px 24px' }
				}}
			>
				<Box
					sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
				>
					<Typography variant="h5" sx={{ fontSize: '18px', mb: 2 }}>
						{translate('title_register_training_by')}
					</Typography>
					<Typography variant="body1" sx={{ fontSize: '20px' }}>
						{Array.from(new Set(trainingInstructorsNames)).join(', ')}
					</Typography>
				</Box>
				<TextField
					placeholder={translate('text_field_register_training_name')}
					type="standard"
					value={newFormationName}
					onChange={(e) => setNewFormationName(e.target.value)}
					InputProps={{
						startAdornment: (
							<Box
								sx={{
									fontSize: {
										xxs: '20px',
										xs: '25px',
										sm: '30px',
										md: '35px',
										lg: '35px',
									},
									color: 'white',
									marginRight: 1
								}}
							>
								<CgFileDocument />
							</Box>
						),
						sx: {...textfieldNewFormationDialog},
					}}
				/>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						width: '100%',
						mt: 2,
					}}
				>
					<Button
						onClick={() => handleDateClick('start')}
						sx={styleButtonsCalendar}
						startIcon={
							<IoCalendar
								style={{ color: 'white', fontSize: '25px', marginLeft: '-30px' }}
							/>
						}
					>
						{startDate
							? formatDate(startDate.getTime())
							: translate('text_field_register_training_start_date')}
					</Button>
					<Button
						onClick={() => handleDateClick('end')}
						sx={styleButtonsCalendar}
						startIcon={
							<IoCalendar
								style={{ color: 'white', fontSize: '25px', marginLeft: '-31px' }}
							/>
						}
					>
						{endDate
							? formatDate(endDate.getTime())
							: translate('text_field_register_training_end_date')}
					</Button>
				</Box>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', backgroundColor: '#002126' }}>
				<Button
					variant="contained"
					startIcon={<FaRegTrashCan />}
					onClick={handleClose}
					sx={{
						backgroundColor: '#002B31',
						color: 'white',
						fontFamily: 'Barlow Condensed !important',
						fontWeight: '500',
						marginRight: '50px',
						borderRadius: '25px',
						fontSize: { xs: 10, sm: 12, md: 12, lg: 16 },
						'&:hover': { backgroundColor: '#893b3b' },
					}}
				>
					{translate('button_discard_changes')}
				</Button>
				<Button
					variant="contained"
					onClick={handleSave}
					startIcon={<CgFileDocument />}
					sx={{
						backgroundColor: '#002B31',
						color: 'white',
						fontFamily: 'Barlow Condensed !important',
						fontSize: { xs: 10, sm: 12, md: 12, lg: 16 },
						fontWeight: '500',
						marginRight: '0px',
						borderRadius: '25px',
						'&:hover': { backgroundColor: '#003e47' },
					}}
				>
					{translate('button_save_training')}
				</Button>
			</DialogActions>
			{isDateTimeNewFormationModalOpen && (
				<Box
					sx={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 2,
					}}
				>
					<DateTimeNewFormationModal
						onClose={handleDateTimeNewFormationModalClose}
						onFilterApply={handleDateFilterApply}
						open={isDateTimeNewFormationModalOpen}
						startDate={dateType === 'start' ? startDate : undefined}
						endDate={dateType === 'end' ? endDate : undefined}
						initialTime={dateType === 'start' ? startTime : endTime}
					/>
				</Box>
			)}
		</Dialog>
	)
}

export default NewFormationDialog
