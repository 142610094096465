import { intervalToDuration } from 'date-fns';

export const calculateDuration = (startTime: string, endTime: string): string => {
    const start = new Date(Number(startTime));
    const end = new Date(Number(endTime));
    const duration = intervalToDuration({ start, end });

    const hours = (duration.hours ?? 0).toString().padStart(2, '0');
    const minutes = (duration.minutes ?? 0).toString().padStart(2, '0');
    const seconds = (duration.seconds ?? 0).toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
};

export const sortByStartTime = (data: any[]): any[] => {
    return [...data].sort((a, b) => Number(a.StartTime) - Number(b.StartTime))
}


