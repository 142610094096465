import { Button } from '@mui/material';
import ludusTheme from '../../../common/theme/ludusTheme.d';
import { FC, ReactNode, MouseEvent } from 'react';

type FilterButtonProps = {
    startIcon?: ReactNode;
    children?: ReactNode;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    selected?: boolean;
    isRiskFilter?: boolean;
    sx?: object;
}

const FilterButton: FC<FilterButtonProps> = ({ startIcon, children, selected, isRiskFilter, sx, ...props }) => {
    return (
        <Button
            variant="contained"
            startIcon={startIcon}
            {...props}
            sx={{
                backgroundColor: selected ? (isRiskFilter ? ludusTheme.palette.warning.main : '#49585B') : '#003236',
                '&:hover': isRiskFilter ? { backgroundColor: ludusTheme.palette.warning.main } : { backgroundColor: '#49585B' },
                marginTop: 1,
                justifyContent: 'flex-start',
                padding: { xxs: 1, xs: 1.5, sm: 1.5, md: 1.5, lg: 1, xl: 1 },
                paddingLeft: { xss: 1, xs: 1.5, sm: 1, md: 1.5, lg: 2, xl: 2 },
                fontFamily: 'Barlow Condensed !important',
                fontWeight: selected && isRiskFilter ? '700 !important' : '400 !important',
                color: selected ? (isRiskFilter ? 'black' : 'white') : "#5D8A8E",
                textAlign: 'left',
                fontSize: { xxs: 10, xs: 14, sm: 14, md: 14, lg: 14, xl: 16 },
                textTransform: 'none',
                ...sx
            }}
        >
            {children}
        </Button>
    );
};

export default FilterButton;
