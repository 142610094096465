import { QueryClient, QueryClientProvider } from 'react-query'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/system'
import { ErrorBoundary } from 'react-error-boundary'
import AppRouter from './AppRouter'
import { NavigationProvider } from './context/navigationContext'
import { ErrorBoundaryComponent } from './errors/ErrorBoundaryComponent'
import ludusTheme from './common/theme/ludusTheme.d'
import reportWebVitals from './reportWebVitals'
import LanguageContextProvider from './context/languageContext'
import { AuthContextProvider } from './context/authContext'
import { ErrorMessageContextProvider } from './context/errorMessageContext'
import { SuccessMessageContextProvider } from './context/successMessageContext'
import ToastSnackbar from './v1/components/common/ToastSnackbar'
import VersionProvider from './context/appVersionContext'
import {
	ApolloClient,
	ApolloLink,
	ApolloProvider,
	HttpLink,
	InMemoryCache,
} from '@apollo/client'
import { FilterProvider } from './context/FilterContext'

const queryClient = new QueryClient()

const httpLink = new HttpLink({ uri: process.env.REACT_APP_STATISTICS_API_URL })

const authLink = new ApolloLink((operation, forward) => {
	const token = sessionStorage.getItem('accessToken')

	operation.setContext({
		headers: {
			authorization: token,
		},
	})

	return forward(operation)
})

const client = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
	queryDeduplication: false,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
		},
	},
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<BrowserRouter>
		<NavigationProvider>
			<ThemeProvider theme={ludusTheme}>
				<LanguageContextProvider>
					<AuthContextProvider>
						<VersionProvider>
							<ErrorMessageContextProvider>
								<SuccessMessageContextProvider>
									<QueryClientProvider client={queryClient}>
										<ApolloProvider client={client}>
											<FilterProvider>
												<CssBaseline />
												<ErrorBoundary FallbackComponent={ErrorBoundaryComponent}>
													<ToastSnackbar />
													<AppRouter />
												</ErrorBoundary>
											</FilterProvider>
										</ApolloProvider>
									</QueryClientProvider>
								</SuccessMessageContextProvider>
							</ErrorMessageContextProvider>
						</VersionProvider>
					</AuthContextProvider>
				</LanguageContextProvider>
			</ThemeProvider>
		</NavigationProvider>
	</BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
