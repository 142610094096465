import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useAuthContext } from '../../context/authContext'
import { GET_TRAINING } from '../services/trainingQueries'

export const useSingleTraining = (trainingId: string) => {
	const [loadedTraining, setLoadedTraining] = useState<any>()
	const { user } = useAuthContext()

	const { loading, error } = useQuery(GET_TRAINING, {
		variables: {
			ClientCode: user.clientCode,
			TrainingId: trainingId,
			offset: 0,
		},
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'cache-first',
		onCompleted: (data) => {
			setLoadedTraining(data.Trainings.trainings[0])
		},
		onError: (e) => {
			console.error(e)
			console.error('onError option:', { error })
		},
	})

	return {
		loading,
		error,
		loadedTraining,
	}
}
