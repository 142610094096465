import { gql } from '@apollo/client';

export const GET_TRAINING = gql`
query Trainings($ClientCode: String!,$TrainingId:ID!) {
  Trainings(ClientCode: $ClientCode,TrainingId:$TrainingId) {
    trainings{
        TrainingId
        Name
        StartTime
        EndTime
        UserId
        TrainingExercises {
            ExerciseId
            Name
            Passed
            UserEmail
            StartTime
            EndTime
            Session {
                Product {
                    Name
                    ProductId
                    AssessmentErrorsDescriptions {
                            ErrorId
                            ErrorDescriptionId
                            Translations {
                              TranslationId
                              es
                              en
                              eu
                              ca
                              es_lt
                              it
                              pr_br
                              pt
                              de
                              pl
                              nl
                              ms
                              tr
                              fr
                              test
                              Comments
                              vi
                          }
                        }
                }
                Trainer {
                    id
                    firstName
                    lastName
                }
            }
            Employee {
                firstName
                lastName
                Client {
                    Name
                    ClientCode
                }
            }
            Assessments {
                AssessmentId
                Name
                IsPassed
                StartTime
                EndTime
                ExerciseId
                Results
                AssessmentErrors{
                    AssessmentId
                    Id
                    ErrorId
                }
 }
        }
    }
  }
}
`;
