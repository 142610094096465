import { Routes, Route } from 'react-router-dom'
import Main from '../layouts/main'
import Training from '../layouts/training'

function Router() {
	return (
		<Routes>
			<>
				<Route path="/statistics/:jwe" element={<Main />} />
				<Route path="/statistics" element={<Main />} />
				<Route path="/training/:course" element={<Training />} />
			</>
		</Routes>
	)
}

export default Router
