import { gql } from '@apollo/client';

export const GET_EXERCISES = gql`
query Exercises($ClientCode: String!, $limit: Int, $offset: Int) {
    Exercises(ClientCode: $ClientCode, limit: $limit, offset: $offset) {
        total
        remaining
        exercises {
            ExerciseId
            Session {
                Trainer {
                    id
                    firstName
                    lastName
                    type
                    Client {
                        Name
                        ComercialName
                    }
                }
                CreatedDate
                SessionId
                Product {
                    Name
                    ProductId
                }
            }
            Passed
            StartTime
            EndTime
            UserEmail
            Employee {
                Client {
                    Name
                    ClientCode
                    ComercialName
                }
            }
        }
    }
}
`;
