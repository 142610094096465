export const formatDate = (timestamp: number | string) => {


  const date = new Date(Number(timestamp));
  return isNaN(date.getTime()) ? "Invalid Date" : date.toLocaleDateString();
};

export const formatDuration = (milliseconds) => {
  let totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${hours}h ${minutes}m ${seconds}s`;
}

export const fomatDateTime = (date: Date, time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  date.setHours(hours, minutes, 0, 0);
  return date.getTime();
};


export const formatDateTraining = (timestamp: number | string) => {
  const date = new Date(Number(timestamp));
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  const formattedDate = `${hours}:${minutes} - ${day}/${month}/${year}`;

  return formattedDate;
};


export const formatTime = (timestamp: number | string) => {
  const date = new Date(Number(timestamp));
  if (isNaN(date.getTime())) {
    return "Invalid Time";
  }

  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};
