import { FC, useContext } from 'react';
import { Button, Box, Typography, SxProps } from '@mui/material';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import { LanguageContext } from '../../../context/languageContext';

type FilterApplyButtonProps = {
    onClick: () => void;
    sx?: SxProps;
    disabled?: boolean
}

const FilterApplyButton: FC<FilterApplyButtonProps> = ({ onClick, sx, disabled }) => {
    const {
        dispatch: { translate },
    } = useContext(LanguageContext);

    return (
        <Button
            onClick={onClick}
            variant="contained"
            disabled={disabled}
            sx={{
                backgroundColor: '#002B31',
                color: "#5D8A8E",
                fontFamily: 'Barlow Condensed !important',
                fontWeight: '500',
                borderRadius: "25px",
                minWidth: '220px',
                fontSize: { xs: 10, sm: 12, md: 12, lg: 14 },
                '&:hover': { backgroundColor: '#003e47', color: 'white' },
                padding: "5px",
                ...sx
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <FilterListIcon sx={{ marginRight: 'auto', paddingLeft: '10px' }} />
                <Typography sx={{ flexGrow: 1, textAlign: 'center', paddingRight: '10px' }}>{translate("button_apply_filter")}</Typography>
            </Box>
        </Button>
    );
}

export default FilterApplyButton;
