import { FC, useContext, useEffect, useReducer } from 'react'
import {
	Table,
	TableBody,
	TableContainer,
	Paper,
	Typography,
	Box,
	Button,
	Checkbox,
	Grid,
} from '@mui/material'
import { CgFileDocument } from 'react-icons/cg'
import NewFormationDialog from '../modals/NewFormationDialog'
import {
	actionButtonStyles,
	checkboxGridStyles,
	checkboxTableContainerStyles,
	headerBoxStyles,
	headerTypographyStyles,
	infoGridStyles,
	infoTableContainerStyles,
	tableContainerStyles,
} from '../../../common/theme/ludusTheme.d'
import { LanguageContext } from '../../../context/languageContext'
import Loader from '../common/Loader'
import FilterSection from './common/FilterSection'
import PaginationControls from './common/PaginationControls'
import TableHeader from './common/TableHeader'
import TableRowComponent from './common/TableRow'
import { useExercises } from '../../hooks/useExecises'
import { initialState, exercisesReducer } from '../../reducers/exercisesReducer'
import { useFilterContext } from '../../../context/FilterContext'
import { shortenName } from '../../utils/shortenName'

const itemsPerPage = 12

type ExercisesTableProps = {
	onAddFormation: (newFormation: {
		course: string
		date: string
		exercises: number
		duration: string
		instructors: string
		completed: number
	}) => void
}

const ExercisesTable: FC<ExercisesTableProps> = ({ onAddFormation }) => {
	const [state, dispatch] = useReducer(exercisesReducer, initialState)

	const {
		loading,
		error,
		loadedExercises,
		totalExercises,
		filteredRows,
		setFilteredRows,
		registerNewTraining,
	} = useExercises()

	const {
		selectedRows,
		selectAll,
		dialogOpen,
		currentPage,
		filteredDates,
		selectedInstructors,
		selectedSimulations,
		selectedCompanies,
		isTableDarkened,
	} = state

	const {
		dispatch: { translate },
	} = useContext(LanguageContext)

	const { setExercisesFilters } = useFilterContext()

	const resetPage = () => {
		dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 })
	}

	useEffect(() => {
		const rows = loadedExercises.filter((exercise) => {
			const exerciseDate = exercise.Session.CreatedDate
			const matchDate = filteredDates
				? exerciseDate >= filteredDates[0].getTime() &&
				exerciseDate <= new Date(filteredDates[1]).setHours(23, 59, 59, 999)
				: true
			const matchInstructor =
				selectedInstructors.length > 0
					? selectedInstructors.includes(
						shortenName(`${exercise.Session.Trainer[0].firstName} ${exercise.Session.Trainer[0].lastName}`)
					)
					: true
			const matchCompany =
				selectedCompanies.length > 0
					? selectedCompanies.includes(
						shortenName(exercise.Employee?.Client?.Name) ||
						shortenName(exercise.Employee?.Client?.ComercialName) ||
						exercise.Employee?.Client?.ClientCode
					)
					: true
			const matchSimulation =
				selectedSimulations.length > 0
					? selectedSimulations.includes(
						shortenName(translate(`label_product_${exercise.Session.Product.Name}`))
					)
					: true
			return matchDate && matchInstructor && matchCompany && matchSimulation
		})

		const sortedRows = rows.sort(
			(a, b) => new Date(b.StartTime).getTime() - new Date(a.StartTime).getTime()
		)

		const selectedRowsList = []
		const unselectedRowsList = []

		sortedRows.forEach((exercise) => {
			if (selectedRows.some((selectedExercise) => selectedExercise.ExerciseId === exercise.ExerciseId)) {
				selectedRowsList.push(exercise)
			} else {
				unselectedRowsList.push(exercise)
			}
		})

		const finalSortedRows = [...selectedRowsList, ...unselectedRowsList]

		setFilteredRows(finalSortedRows)
	}, [
		filteredDates,
		selectedInstructors,
		selectedSimulations,
		selectedCompanies,
		loadedExercises,
		selectedRows,
		setFilteredRows,
		translate
	])
	useEffect(() => {
		const allSelected = paginatedRows.every((row) =>
			selectedRows.some((selectedRow) => selectedRow.ExerciseId === row.ExerciseId)
		)
		dispatch({ type: 'SET_SELECT_ALL', payload: allSelected })
	}, [currentPage, filteredRows, selectedRows])

	const handleCheckboxChange = (Exercise: any) => {
		const isSelected = selectedRows.find(
			(selectedExercise) => selectedExercise.ExerciseId === Exercise.ExerciseId
		);

		dispatch({
			type: 'SET_SELECTED',
			payload: isSelected
				? selectedRows.filter((selectedExercise) => selectedExercise.ExerciseId !== Exercise.ExerciseId)
				: [...selectedRows, Exercise],
		})
	}

	const handleSelectAllChange = () => {
		const newSelectAll = !selectAll
		const newSelectedRows = newSelectAll
			? [...new Set([...selectedRows, ...paginatedRows])]
			: selectedRows.filter(
				(row) =>
					!paginatedRows.some(
						(paginatedRow) => paginatedRow.ExerciseId === row.ExerciseId
					)
			)
		dispatch({
			type: 'TOGGLE_SELECT_ALL',
			payload: newSelectedRows,
		})
		dispatch({
			type: 'SET_SELECT_ALL',
			payload: newSelectAll,
		})
	}

	const handleDialogClose = () => {
		dispatch({ type: 'SET_DIALOG_OPEN', payload: false })
	}

	const handleDialogSave = async (
		newFormationName: string,
		startDate: Date,
		endDate: Date,
		startTime: string,
		endTime: string
	) => {
		await registerNewTraining(
			newFormationName,
			startDate,
			endDate,
			startTime,
			endTime,
			selectedRows,
			onAddFormation
		)
	}

	const handleResetFilters = () => {
		dispatch({ type: 'RESET_FILTERS' })
		setExercisesFilters({
			trainer: [],
			company: [],
			product: [],
			date: [],
		})
		resetPage()
	}

	const handlePrevPage = () => {
		if (currentPage > 1) {
			dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 })
		}
	}

	const handleNextPage = () => {
		if (currentPage < Math.ceil(filteredRows.length / itemsPerPage)) {
			dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage + 1 })
		}
	}

	const handleIsTableDarkenedChange = (isDarkened: boolean) => {
		dispatch({ type: 'SET_IS_TABLE_DARKENED', payload: isDarkened })
	}

	const handleDeselectAll = () => {
		dispatch({ type: 'SET_SELECTED', payload: [] })
		dispatch({ type: 'SET_SELECT_ALL', payload: false })
	}

	const instructors = Array.from(
		new Map(
			loadedExercises.map((exercise) => [
				exercise.Session.Trainer[0].id,
				{
					id: exercise.Session.Trainer[0].id,
					name: `${exercise.Session.Trainer[0].firstName} ${exercise.Session.Trainer[0].lastName}`,
				},
			])
		).values()
	).sort((a, b) => a.name.localeCompare(b.name))

	const companies = Array.from(
		new Map(
			loadedExercises.map((exercise) => [
				exercise.Employee?.Client?.ClientCode,
				{
					ClientCode: exercise.Employee?.Client?.ClientCode,
					name:
						exercise.Employee?.Client?.Name ||
						exercise.Employee?.Client?.ComercialName,
				},
			])
		).values()
	)

	const simulations = Array.from(
		new Map(
			loadedExercises.map((exercise) => [
				exercise.Session.Product.ProductId,
				{
					id: exercise.Session.Product.ProductId,
					name: exercise.Session.Product.Name,
				},
			])
		).values()
	).sort((a, b) => a.name.localeCompare(b.name))

	const paginatedRows = filteredRows.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	)

	if (loading) return <Loader />
	if (error) return <p>Error: {error.message}</p>

	return (
		<Box
			sx={{ position: 'relative' }}
			onClick={() => handleIsTableDarkenedChange(false)}
		>
			<TableContainer sx={tableContainerStyles}>
				<Box sx={headerBoxStyles}>
					<Typography variant="h5" sx={headerTypographyStyles}>
						{translate('title_table_exercises')}
					</Typography>
					<Button
						variant="contained"
						startIcon={<CgFileDocument />}
						sx={{
							...actionButtonStyles,
							'&:hover': { backgroundColor: '#003e47' },
							visibility: selectedRows.length > 0 ? 'visible' : 'hidden',
						}}
						onClick={() => dispatch({ type: 'SET_DIALOG_OPEN', payload: true })}
					>
						{translate('button_register_new_training')}
					</Button>
				</Box>

				{totalExercises === 0 ? (
					<Box sx={{ padding: '50px', textAlign: 'center', color: 'white' }}>
						<Typography variant="h4" sx={{ margin: '30px', fontWeight: 'bold' }}>
							{translate('title_no_data_exercises')}
						</Typography>
						<Typography variant="h5" sx={{ marginBottom: '60px' }}>
							{translate('text_no_data_exercises_pt1')} <br />
							{translate('text_no_data_exercises_pt2')} <br />
							{translate('text_no_data_exercises_pt3')}
						</Typography>
					</Box>
				) : (
					<Grid container>
						<FilterSection
							tableId="exercises"
							filters={['trainer', 'company', 'product', 'date']}
							translate={translate}
							onResetFilters={handleResetFilters}
							instructors={instructors}
							companies={companies}
							simulations={simulations}
							handleInstructorFilterApply={(instructors: string[]) =>
								dispatch({ type: 'SET_SELECTED_INSTRUCTORS', payload: instructors })
							}
							handleCompanyFilterApply={(companies: string[]) =>
								dispatch({ type: 'SET_SELECTED_COMPANIES', payload: companies })
							}
							handleSimulationFilterApply={(simulations: string[]) =>
								dispatch({ type: 'SET_SELECTED_SIMULATIONS', payload: simulations })
							}
							handlePeriodFilterApply={(dates: [Date, Date] | null) =>
								dispatch({ type: 'SET_FILTERED_DATES', payload: dates })
							}
							onIsTableDarkenedChange={handleIsTableDarkenedChange}
							onPageReset={resetPage}
							state={state}
						/>
						{paginatedRows.length === 0 ? (
							<Grid sx={infoGridStyles}>
								<Box sx={{ padding: '50px', textAlign: 'center', color: 'white' }}>
									<Typography variant="h4" sx={{ margin: '30px', fontWeight: 'bold' }}>
										{translate('title_no_data_exercises')}
									</Typography>
									<Typography variant="h5" sx={{ marginBottom: '60px' }}>
										{translate('text_no_data_exercises_pt1')} <br />
										{translate('text_no_data_exercises_pt2')} <br />
										{translate('text_no_data_exercises_pt3')}
									</Typography>
								</Box>
							</Grid>
						) : (
							<>
								<Grid sx={checkboxGridStyles}>
									<TableContainer
										component={Paper}
										sx={{
											...checkboxTableContainerStyles,
											...(isTableDarkened && {
												opacity: 0.3,
												pointerEvents: 'none',
											}),
										}}
									>
										<Table
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												gap: {
													xxs: '3px',
													xs: '1px',
													sm: '100%',
													md: '0.5px',
													lg: '7px',
													xl: '7px',
												},
												'& .MuiTableCell-root': {
													color: 'white',
												},
											}}
										>
											<Checkbox
												sx={{ color: 'white', transform: 'scale(0.9)' }}
												checked={selectAll}
												onChange={handleSelectAllChange}
											/>
											{paginatedRows.map((row: any) => (
												<Checkbox
													key={row.ExerciseId}
													sx={{ color: 'white', transform: 'scale(0.9)' }}
													checked={selectedRows.some(
														(selectedExercise) =>
															selectedExercise.ExerciseId === row.ExerciseId
													)}
													onChange={() => {
														handleCheckboxChange(row)
													}}
												/>
											))}
										</Table>
									</TableContainer>
								</Grid>

								<Grid sx={infoGridStyles}>
									<TableContainer
										component={Paper}
										sx={{
											minWidth: {
												xxs: 700,
												xs: 700,
												sm: 700,
												md: 520,
												lg: 600,
												xl: 650,
											},
											...infoTableContainerStyles,
											...(isTableDarkened && {
												opacity: 0.3,
												pointerEvents: 'none',
											}),
										}}
									>
										<Table
											sx={{
												'& .MuiTableCell-root': {
													color: 'white',
													padding: {
														xxs: '10px 0px',
														xs: '12px 0px',
														sm: '10px 0px',
														md: '10px 0px',
														lg: '10px 0px',
														xl: '10px 0px',
													},
													borderRight: '2px solid white',
												},
												'& .MuiTableCell-head': {
													fontWeight: 'bold',
												},
											}}
										>
											<TableHeader
												translate={translate}
												headerKeyIds={[
													'column_table_date',
													'column_table_duration',
													'column_table_trainer',
													'column_table_employee',
													'column_table_company_filter',
													'column_table_simulation_filter',
													'column_table_result',
												]}
											/>
											<TableBody>
												{paginatedRows.map((row: any) => (
													<TableRowComponent
														key={row.ExerciseId}
														row={row}
														selected={selectedRows}
														parentTable={'exercises'}
														id={row.ExerciseId}
														translate={translate}
													/>
												))}
											</TableBody>
										</Table>
									</TableContainer>
									<PaginationControls
										currentPage={currentPage}
										totalPages={Math.ceil(filteredRows.length / itemsPerPage)}
										onPrevPage={handlePrevPage}
										onNextPage={handleNextPage}
									/>
								</Grid>
							</>
						)}
						<NewFormationDialog
							open={dialogOpen}
							selectedRows={selectedRows}
							onClose={handleDialogClose}
							onSave={handleDialogSave}
							onDeselectAll={handleDeselectAll}
						/>
					</Grid>
				)}
			</TableContainer>
		</Box>
	)
}

export default ExercisesTable
