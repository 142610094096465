import { useContext, useEffect } from 'react'
import Router from './routes/router'
import { useNavigation } from '../context/navigationContext'
import { Box } from '@mui/material'
import ModeWarning from './components/common/ModeWarning'
import { customAppStyles } from '../common/theme/ludusTheme.d'
import { LanguageContext } from '../context/languageContext'
import { useLocation } from 'react-router-dom'

const languages = {
	es: 'es',
	en: 'en',
	'es-lt': 'es-lt',
	eu: 'eu',
	ca: 'ca',
	de: 'de',
	fr: 'fr',
	it: 'it',
	ms: 'ms',
	nl: 'nl',
	pl: 'pl',
	pt: 'pt',
	'pt-br': 'pt-br',
	tr: 'tr',
}

const App = () => {
	const { navigateToError } = useNavigation()
	const {
		dispatch: { setLanguage },
	} = useContext(LanguageContext)
	const location = useLocation()

	useEffect(() => {
		if (
			window.localStorage.getItem('language') &&
			window.localStorage.getItem('language') ===
				window.localStorage.getItem('language').toUpperCase()
		) {
			window.localStorage.setItem(
				'language',
				window.localStorage.getItem('language').toLowerCase()
			)
			setLanguage(window.localStorage.getItem('language').toLowerCase())
		}

		const searchString = location.search.slice(1).toLowerCase().trim()

		for (const [key, value] of Object.entries(languages)) {
			if (searchString === key) {
				setLanguage(value)
				break
			}
		}
	}, [location])

	useEffect(() => {
		const handleErrors = (error) => {
			console.error('Error captured', error)
			navigateToError()
		}

		window.onerror = (...params) =>
			handleErrors(new Error('Window onerror captured'))

		window.onunhandledrejection = (event) =>
			handleErrors(new Error('Unhandled rejection captured'))

		return () => {
			window.onerror = null
			window.onunhandledrejection = null
		}
	}, [navigateToError])

	return (
		<>
			<Box component={'div'} sx={customAppStyles.contentBox}>
				<Router />
				{/* <ModeWarning/> */}
			</Box>
		</>
	)
}

export default App
