import { shortenName } from '../../utils/shortenName'
import GenericModal from '../common/GenericModal'
import { LanguageContext } from '../../../context/languageContext'
import { useContext } from 'react'

const CompanyModal = ({ onClose, onFilterApply, companies, tableId, onPageReset }) => {
	const {
		dispatch: { translate },
	} = useContext(LanguageContext)

	const shortenedCompanies = companies.map((company: { name: string }) =>
		shortenName(company.name)
	)

	return (
		<>
			<GenericModal
				tableId={tableId}
				modalType="company"
				title={translate('search_company')}
				items={shortenedCompanies.filter((element) => element !== undefined)}
				onClose={onClose}
				onFilterApply={onFilterApply}
				onPageReset={onPageReset}
			/>
		</>
	)
}

export default CompanyModal
