import { gql } from '@apollo/client';

export const GET_TRAININGS = gql`
query Trainings($ClientCode: String!, $limit: Int, $offset: Int) {
  Trainings(ClientCode: $ClientCode, limit: $limit, offset: $offset) {
        total
        remaining
        trainings {
            TrainingId
            Name
            StartTime
            EndTime
            UserId
            TrainingExercises {
                ExerciseId
                Name
                Passed
                StartTime
                EndTime
                Session {
                    Product {
                        Name
                        ProductId
                    }
                    Trainer {
                        id
                        firstName
                        lastName
                    }
                }
                Employee {
                    Client {
                        Name
                        ClientCode
                    }
                }
            }
        }
    }
}

`;
