import { FC, useState } from 'react'
import {
	Box,
	Typography,
	TextField,
	Checkbox,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Button,
	InputAdornment,
} from '@mui/material'
import { IoIosSearch } from 'react-icons/io'
import { GoChevronLeft, GoChevronRight } from 'react-icons/go'
import ludusTheme, {
	genericModalBoxStyles,
} from '../../../common/theme/ludusTheme.d'
import FilterApplyButton from '../buttons/FilterApplyButton'
import CloseModalButton from '../buttons/CloseModalButton'
import { useFilterContext } from '../../../context/FilterContext'

export type GenericModalProps = {
	title?: string
	items: string[]
	onClose?: () => void
	onFilterApply: (selectedItems: string[]) => void
	onPageReset: () => void
	modalType: 'trainer' | 'company' | 'product' | 'date'
	tableId: string
}

const GenericModal: FC<GenericModalProps> = ({
	tableId,
	title,
	items,
	onClose,
	onFilterApply,
	onPageReset,
	modalType,
}) => {
	const {
		exercisesFilters,
		setExercisesFilters,
		trainingsFilters,
		setTrainingsFilters,
		assessmentsFilters,
		setAssessmentsFilters,
	} = useFilterContext()
	const [searchTerm, setSearchTerm] = useState('')
	const [page, setPage] = useState(1)
	const itemsPerPage = 5

	const selectedItems = (() => {
		switch (tableId) {
			case 'trainings':
				return trainingsFilters
			case 'exercises':
				return exercisesFilters
			case 'assessments':
				return assessmentsFilters
		}
	})()

	const setSelectedItems = (() => {
		switch (tableId) {
			case 'trainings':
				return setTrainingsFilters
			case 'exercises':
				return setExercisesFilters
			case 'assessments':
				return setAssessmentsFilters
		}
	})()

	const handleToggle = (item: string) => {
		setSelectedItems((prev) => ({
			...prev,
			[modalType]: prev[modalType].includes(item)
				? prev[modalType].filter((i) => i !== item)
				: [...prev[modalType], item],
		}))
	}

	const handleFilterApply = () => {
		onPageReset()
		onFilterApply(selectedItems[modalType])
		onClose()
	}

	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value)
		setPage(1)
	}

	const filteredItems = items.filter((item) =>
		item && item.toLowerCase().includes(searchTerm.toLowerCase())
	)

	const totalPages = Math.max(1, Math.ceil(filteredItems.length / itemsPerPage))

	const paginatedItems = filteredItems.slice(
		(page - 1) * itemsPerPage,
		page * itemsPerPage
	)

	const handlePrevPage = () => {
		if (page > 1) setPage(page - 1)
	}

	const handleNextPage = () => {
		if (page < totalPages) setPage(page + 1)
	}

	return (
		<Box sx={genericModalBoxStyles}>
			<Box sx={{ padding: '10px' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<TextField
						placeholder={title}
						variant="standard"
						onChange={handleSearchChange}
						InputProps={{
							startAdornment: (
								<InputAdornment
									position="start"
									sx={{ marginTop: '2px', marginLeft: '10px' }}
								>
									<IoIosSearch color="white" size={20} />
								</InputAdornment>
							),
							disableUnderline: true,
							sx: {
								backgroundColor: '#BEBEBE',
								borderRadius: '50px',
								padding: '2px',
								color: 'white',
								minWidth: '230px',
								fontSize: { xs: 10, sm: 10, md: 10, lg: 12, xl: 14 },
							},
						}}
					/>
					<Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
						<Button
							onClick={handlePrevPage}
							disabled={page === 1}
							sx={{
								minWidth: '24px',
								minHeight: '24px',
								color: 'rgba(0, 0, 0, 0.26)',
								padding: 0,
								backgroundColor: 'transparent',
								borderRadius: '20px',
								'&:hover': {
									backgroundColor: 'rgba(0, 0, 0, 0.08)',
									borderRadius: '20px',
								},
							}}
						>
							<GoChevronLeft size={25} />
						</Button>
						<Typography
							variant="body1"
							sx={{ color: 'rgba(0, 0, 0, 0.6)', margin: 1 }}
						>
							{filteredItems.length === 0 ? '0/0' : `${page}/${totalPages}`}
						</Typography>
						<Button
							onClick={handleNextPage}
							disabled={page === totalPages}
							sx={{
								minWidth: '24px',
								minHeight: '24px',
								color: 'rgba(0, 0, 0, 0.26)',
								padding: 0,
								backgroundColor: 'transparent',
								borderRadius: '20px',
								'&:hover': {
									backgroundColor: 'rgba(0, 0, 0, 0.08)',
									borderRadius: '20px',
								},
							}}
						>
							<GoChevronRight size={25} />
						</Button>
					</Box>
				</Box>
				<List sx={{ maxHeight: '200px', overflow: 'auto', color: 'black' }}>
					{paginatedItems.map((item) => (
						<ListItem
							key={item}
							onClick={() => handleToggle(item)}
							sx={{
								padding: '0 8px',
								height: '25px',
								'&:hover': {
									backgroundColor: 'rgba(0, 0, 0, 0.08)',
								},
							}}
						>
							<ListItemIcon sx={{ minWidth: '30px' }}>
								<Checkbox
									edge="start"
									checked={selectedItems[modalType].includes(item)}
									tabIndex={-1}
									disableRipple
									sx={{
										color: selectedItems[modalType].includes(item) ? 'black' : 'black',
										transform: 'scale(0.7)',
										'&.Mui-checked': {
											color: ludusTheme.palette.primary.main,
										},
									}}
								/>
							</ListItemIcon>
							<ListItemText
								primary={item}
								sx={{
									margin: 0,
									fontSize: '0.875rem',
									cursor: 'pointer',
									color: selectedItems[modalType].includes(item)
										? ludusTheme.palette.primary.main
										: 'inherit',
								}}
							/>
						</ListItem>
					))}
				</List>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<FilterApplyButton onClick={handleFilterApply} />
					<CloseModalButton onClick={onClose} />
				</Box>
			</Box>
		</Box>
	)
}

export default GenericModal
