import { useContext, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import ludusTheme from '../../common/theme/ludusTheme.d';
import TabPanel from '../components/tabs/TabPanel';
import Activity from '../components/tabs/Activity';
import { LanguageContext } from '../../context/languageContext';
// import Company from '../components/tabs/Company';
// import Performance from '../components/tabs/Performance';

const Main = () => {
	const [value, setValue] = useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const {
		dispatch: { translate },
	} = useContext(LanguageContext)

	//! COMENTADO YA QUE DE MOMENTO ES SOLO LA PESTAÑA ACTIVIDAD

	return (
		<>
			<Tabs
				value={value}
				onChange={handleChange}
				aria-label="styled tabs example"
				sx={{
					borderTopLeftRadius: '10px',
					borderTopRightRadius: '10px',
					borderBottomRightRadius: 0,
					borderBottomLeftRadius: 0,
					backgroundColor: "transparent",
					color: 'white',
					boxShadow: 0,
					marginLeft: 3,
					marginRight: 3,
					marginBottom: -6,
				}}
				TabIndicatorProps={{ style: { background: 'none' } }}
			>
				<Tab
					label={translate("label_tab_activity")}
					sx={{
						clipPath: 'polygon(0 0, 85% 0, 100% 100%, 0 100%)',
						color: 'white !important',
						backgroundColor: "#001A1D",
						paddingLeft: "0px",
						paddingRight: 0,
						fontSize: '1.4rem',
						fontFamily: 'Barlow Condensed',
						fontWeight: '700',
						width: "200px",
						marginRight: '0px',
						zIndex: 2,
						'&.Mui-selected': {
							backgroundColor: ludusTheme.palette.secondary.main,
						},
						'&.Mui-focusVisible': {
							backgroundColor: ludusTheme.palette.secondary.main,
						},
					}}
				/>
				{/* <Tab
					label={translate("label_tab_company")}
					sx={{
						clipPath: 'polygon(0 0, 85% 0, 100% 100%, 15% 100%)',
						color: 'white !important',
						backgroundColor: "#001A1D",
						paddingLeft: "0px",
						paddingRight: 0,
						fontSize: '1.4rem',
						fontFamily: 'Barlow Condensed',
						fontWeight: '700',
						width: "200px",
						marginLeft: '-25px',
						marginRight: '0px',
						zIndex: 1,
						'&.Mui-selected': {
							backgroundColor: ludusTheme.palette.secondary.main,
						},
						'&.Mui-focusVisible': {
							backgroundColor: ludusTheme.palette.secondary.main,
						},
					}}
				/> */}

				{/* <Tab
					label={translate("label_tab_performance")}
					sx={{
						clipPath: 'polygon(0 0, 85% 0, 100% 100%, 15% 100%)',
						color: 'white !important',
						backgroundColor: "#001A1D",
						paddingLeft: "0px",
						paddingRight: 0,
						fontSize: '1.4rem',
						fontFamily: 'Barlow Condensed',
						fontWeight: '700',
						width: "200px",
						marginLeft: '-25px',
						marginRight: '0px',
						zIndex: 0,
						'&.Mui-selected': {
							backgroundColor: ludusTheme.palette.secondary.main,
						},
						'&.Mui-focusVisible': {
							backgroundColor: ludusTheme.palette.secondary.main,
						},
					}}
				/> */}
			</Tabs>
			<TabPanel value={value} index={0}>
				<Activity />
			</TabPanel>
			{/* <TabPanel value={value} index={1}>
				<Company />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<Performance />
			</TabPanel> */}
		</>
	);
};

export default Main
