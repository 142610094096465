import { FC, useContext } from 'react'
import GenericModal from '../common/GenericModal'
import { LanguageContext } from '../../../context/languageContext'
import { shortenName } from '../../utils/shortenName'

type SimulationModalProps = {
	onClose: () => void
	onFilterApply: (selectedSimulations: string[]) => void
	simulations: { id: string; name: string }[]
	tableId: string
	onPageReset: () => void
}

const SimulationModal: FC<SimulationModalProps> = ({
	onClose,
	onFilterApply,
	simulations,
	tableId,
	onPageReset
}) => {
	const {
		dispatch: { translate },
	} = useContext(LanguageContext)

	const shortenedSimulationNames = simulations.map((simulation) =>
		shortenName(translate(`label_product_${simulation.name}`))
	)

	return (
		<GenericModal
			tableId={tableId}
			modalType="product"
			title={translate('search_simulation')}
			items={shortenedSimulationNames}
			onClose={onClose}
			onFilterApply={onFilterApply}
			onPageReset={onPageReset}
		/>
	)
}

export default SimulationModal
