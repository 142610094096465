import { FC } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { GoChevronLeft, GoChevronRight } from 'react-icons/go'
import ludusTheme from '../../../../common/theme/ludusTheme.d'

interface PaginationControlsProps {
	currentPage: number
	totalPages: number
	onPrevPage: () => void
	onNextPage: () => void
}

const PaginationControls: FC<PaginationControlsProps> = ({
	currentPage,
	totalPages,
	onPrevPage,
	onNextPage,
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: '10px',
				marginBottom: '15px',
			}}
		>
			<Button
				onClick={onPrevPage}
				disabled={currentPage === 1}
				sx={{
					minWidth: '24px',
					minHeight: '24px',
					color: ludusTheme.palette.info.main,
					padding: 0,
					backgroundColor: 'transparent',
					borderRadius: '20px',
					'&:hover': {
						backgroundColor: 'rgb(53, 93, 98, 0.3)',
						borderRadius: '20px',
					},
					'&.Mui-disabled': {
						color: ludusTheme.palette.info.main,
					},
				}}
			>
				<GoChevronLeft size={25} />
			</Button>
			<Typography
				variant="body1"
				sx={{ color: ludusTheme.palette.info.main, margin: 1 }}
			>{totalPages === 0 ? '0/0' : `${currentPage}/${totalPages}`}</Typography>
			<Button
				onClick={onNextPage}
				disabled={currentPage === totalPages}
				sx={{
					minWidth: '24px',
					minHeight: '24px',
					color: ludusTheme.palette.info.main,
					padding: 0,
					backgroundColor: 'transparent',
					borderRadius: '20px',
					'&:hover': {
						backgroundColor: 'rgb(53, 93, 98, 0.3)',
						borderRadius: '20px',
					},
					'&.Mui-disabled': {
						color: ludusTheme.palette.info.main,
					},
				}}
			>
				<GoChevronRight size={25} />
			</Button>
		</Box>
	)
}

export default PaginationControls
