import React, { useState, useCallback, useContext, ReactNode, createContext, FC } from 'react';

type Props = {
  children: ReactNode;
};

export const ErrorMessageContext = createContext({
  error: { message: '' },
  addError: (message: string) => {},
  removeError: () => {},
});

export const ErrorMessageContextProvider: FC<Props> = ({ children }): JSX.Element => {
  const [error, setError] = useState({ message: '' });

  const removeError = () => {
    setError({ message: '' });
  };

  const addError = (message: string) => {
    setError({ message });
  };

  const contextValue = {
    error,
    addError: useCallback((message: string) => addError(message), []),
    removeError: useCallback(() => removeError(), []),
  };

  return (
    <ErrorMessageContext.Provider value={contextValue}>
      {children}
    </ErrorMessageContext.Provider>
  );
};

export function useErrorMessageContext() {
  const { error, addError, removeError } = useContext(ErrorMessageContext);
  return { error, addError, removeError };
}
